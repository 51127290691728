.jemek__policy-and-terms__section-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 15%;
    padding-bottom: 10%;
    background-color: #070a37;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.jemek__policy-and-terms__contents {
    padding: 8% 8%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -3.8rem;
    background-color: white;
}

.jemek__policy-and-terms__contents h2 {
    margin-bottom: 4%;
    font-size: 2rem;
}

.jemek__policy-and-terms__paragraph {
    margin-bottom: 2%;
}


.jemek__policy-and-terms__paragraph a {
    color: #4586d6;
    font-size: var(--step-1);
    transition: 0.5s;
}

.jemek__policy-and-terms__paragraph a:hover {
    color: #0f75f3;
}

