.jemek__contactus-column-one,
.jemek__contactus-column {
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0;
  width: auto;
}

.jemek__contactpage-columnone.col-xl-6.col-xl-6.mb-4,
.jemek__contactpage-columntwo.col-xl-6.col-xl-6.mb-4 {
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.jemek__contactus-row.row {
  max-width: 1320px;
  margin: 0 auto;
}



.jemek__contactus-form-btn button {
  border-radius: 0.05rem!important;
  padding: 0.4rem 1.3rem;
  font-size: 0.9rem;
  transition: 0.5s;
}

.jemek__contactus-form-btn button:hover {
  background-color: #5997e9!important;
  color: whitesmoke!important;
}

.hidden {
  opacity: 0;
  display: none;
}

.message-container {
  width: 50%!important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-container p {
  font-size: 1.4rem;
  font-weight: 400;
  text-transform: uppercase;
  width: 100%;
}

.contactform-message-highlight {
  color: #5997e9;
}

.mail_text {
  width: 100%;
  float: left;
  font-size: 1rem;
  color: #f1f1f1 !important;
  background-color: #101e2f;
  border: none;
  border-bottom: 0.0625rem solid;
  padding: 0.9375rem;
  margin-top: 0.125rem;
}

.mail_text.input {
  background-color: #101e2f;
}

.message-bt {
  color: #f1f1f1;
  width: 100%;
  height: 13.75rem !important;
  font-size: 1.125rem;
  background-color: #101e2f;
  padding: 1.25rem 0.9375rem 0px 0.9375rem;
  margin-top: 1.875rem;
}

.success-message {
  justify-content: center;
  font-size: 1.5rem;
  align-items: center;
}
.jemek__contactus-column.messages {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 400;
}

.jemek__contact-us__fine-print small {
  font-weight: 400;
  font-size: var(--step--1)!important;
}


@media screen and (max-width: 480px) {

  .jemek__contactus {
    padding-bottom: 2rem;
}
  .message-container {
    width: 90%!important;
}

.message-container  p {
  font-size: 0.6rem;
  width: 100%;
}

.success-message {
  margin-left: 1.8rem;
}

.jemek__contactus-row {
  flex-direction: column;
}

.jemek__contactus-column-one,
.jemek__contactus-column {
  padding-left: 15px;
  padding-right: 15px;
}



.jemek__contactus-column-one {
  margin-bottom: 0rem;
  padding-top: 1.5rem;
}


.mail_text {
  font-size: 0.55em !important;
}


.message-bt {
  font-size: 0.55em !important;
  height: 10rem!important;
}

.jemek__contactus-column-one-item h1 {
  font-size: 2.2em !important;
}

.jemek__contactus-formbtn-one {
  font-size: 0.55em !important;
  border-radius: 1px !important;
}

.jemek__contactus-formbtn-one button {
  padding: 1.05em;
}

.jemek__contactus-form-btn button {
  border-radius: 0.05rem!important;
  padding: 0.3rem 1rem;
}



.jemek__contactus-column.messages {
  font-size: 0.8rem;

}

}

@media screen and (min-width: 481px) and (max-width:768px) {

  .message-container {
    width: 100%!important;
    padding-bottom: 2rem;
  }
  
  .message-container p {
    font-size: 1rem;
    width: 100%;
    margin-left: 2rem;
}




.jemek__contactus-row {
  flex-direction: column;
}

.jemek__contactus-column-one,
.jemek__contactus-column {
  padding-left: 15px;
  padding-right: 15px;
}

.jemek__contactus-column-one {
  margin-bottom: 0.2rem!important;
}

.jemek__contactus-column-one-item h1 {
  font-size: 3.5rem!important;
}

.jemek__contactus-column-one-item p {
  font-size: 0.8rem;
}

.mail_text {
  font-size: 0.8rem!important;
}

.message-bt {
  font-size: 0.8rem!important;
}

.jemek__contactus-form-btn button {
  border-radius: 0.05rem!important;
  padding: 0.4rem 1rem;
  font-size: 0.7rem;
}

.jemek__contactus-column.messages {

  font-size: 1.2rem;

}


}

/* Mobile */

  
  /* Tablet */
  @media screen and (min-width: 769px) and (max-width: 1024px) {
   
  }
  
  /* Desktop */
  @media (min-width: 1025px) {

    .jemek__header-row {
      width: 100% !important;
      margin: 0 !important;
      display: flex;
  }

  .jemek__header-column {
    width: 100%!important;
  }
  
  .jemek__header-column-two {
    width:100%!important;
  }

    .jemek__contactus-row {
      flex-direction: row;
    }
  
    .jemek__contactus-column-one,
    .jemek__contactus-column {
      padding-left: 15px;
      padding-right: 15px;
    }

    
  }