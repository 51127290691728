.jemek__ourteam-header {
    width: 100%;
    display:flex;
    flex-direction: row;
    padding-top: 15%;
    padding-bottom: 10%;
    background-image: url(/home/ghost/Documents/codingprojs/react_app_jemek/frontend_react/src/assets/abstract-blue.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.jemek__ourteam-content-two {
    max-width: 1920px;
    margin: 0 auto;
}

.jemek__ourteam-header-container-text {
    color: white;
}

.jemek__ourteam-content-textbox p {
    background-color: #fbfbfb;
    padding: 3.125rem;
    padding-left: 13%;
    padding-right: 13%;
    font-size: 1.1rem;
    width: 90%;
    line-height: 2.6rem;
    font-weight: 300;
    color: rgb(0, 0, 0);
    margin-top: -4.5rem!important;
    margin-left: auto;
    margin-right: auto;
}


.jemek__ourteam-content-image img {
    width: 100%;
    height: 20rem;
    border-radius: 10rem;
    z-index: 0;
}

.jemek__ourteam-content-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
    margin-top: -3rem;
    background-color: white;
    z-index: 1;
    transform: translate(1%, -20%);
}
.jemek__ourteam-highlight {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.jemek__ourteam-highlight a :hover {
    color: #5997e9;
}


.jemek__ourteam-content-text h3, h4 {
    color: #241d1d;
}



.jemek__ourteam-content-text h4 {
    font-size: 0.8rem;
}
.jemek__ourteam-text-items-two p {
    font-size: 0.8rem;
}

.jemek__ourteam-content-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20rem;
    transition: transform 0.3s ease-in-out;
}

.jemek__ourteam-content-item:hover{
    transform: scale(1.05);
}

.jemek__ourteam-content-text-header h3 {
    font-weight: 300;
}

.jemek__ourteam-text-items-two p {
    color: black;
}

.jemek__ourteam-content-item:hover .jemek__ourteam-content-text h3,
.jemek__ourteam-content-item:hover .jemek__ourteam-content-text h4,
.jemek__ourteam-content-item:hover .jemek__ourteam-content-text p {
    color: #5997e9;
    transition: 0.5s;
}

.hidden-section {
    opacity: 0;
}

@media screen and (max-width: 480px) {
    .jemek__ourteam-header {
        padding-top: 20%;
        padding-bottom: 15%;
    }

    .jemek__ourteam-header-container-text h1 {
        font-size: 1rem;
    }

    .jemek__ourteam-content-textbox p {
        padding: 3.508333%;
        padding-left: 8%;
        padding-right: 8%;
        font-size: 0.4rem;
        width: 90%;
        line-height: 1.3rem;
        margin-top: -2rem!important;
    }

    .jemek__ourteam-content-header-container h2 {
        font-size: 0.9rem;
    }

    .jemek__ourteam-content-image img {
        width: 100%;
        height: auto;
        border-radius: 0rem;
        z-index: 0;
    }

    .jemek__ourteam-content-text {
        padding: 5%;
        margin-top: -0.5rem;
    }

    .jemek__ourteam-content-text-header h3 {
        font-size: 0.8rem;
    }

    .jemek__ourteam-content-text h4 {
        font-size: 0.6rem;
    }

    .jemek__ourteam-text-items-two p {
        font-size: 0.5rem;
    }
    

    
}

@media screen and (min-width:481px) and (max-width: 768px) {
    .jemek__ourteam-header {
        padding-top: 25%;
        padding-bottom: 20%;
    }

    .jemek__ourteam-header-container-text h1 {
        font-size: 1.5rem;
    }

    .jemek__ourteam-content-textbox p {
        padding: 3.508333%;
        padding-left: 12%;
        padding-right: 12%;
        font-size: 0.8rem;
        width: 100%;
        line-height: 1.5rem;
        margin-top: -4rem;
    }

    .jemek__ourteam-content-header-container h2{
        font-size: 1rem;
    }


.jemek__ourteam-content-image img {
    width: 100%;
    height: 14rem;
    border-radius: 0rem;
    z-index: 0;
}

.jemek__ourteam-content-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    margin-top: -1rem;
    background-color: white;
    z-index: 1;
    transform: translate(1%, -20%);
}

.jemek__ourteam-content-text h3, h4 {
    color: #241d1d;
    font-size: 1rem;
    font-weight: 300;
}
.jemek__ourteam-text-items-two p {
    font-size: 0.6rem;
}

}


@media screen and (min-width:769px) and (max-width: 1024px) {


}

@media screen and (min-width: 1320px) {
    .jemek__ourteam-content-textbox p {
        font-size: var(--step-0)!important;
    }
}