.jemek__cookies-info__section-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 20%;
    padding-bottom: 15%;
    background-color: #070a37;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.jemek__cookies-info__body-container {

    flex-direction: row;
    width: 100%!important;

}

.jemek__cookies-info__content {
    padding-top: 5%;
}

.jemek__cookies-info__link {
    transition: 0.5s;
    color: #316098!important;
}

.jemek__cookies-info__link:hover {
    color: #7399c6!important;
}

.jemek__cookies-info__h3 {
    margin-top: 2%!important;
}

.jemek__cookies-info__body-row {
    display: flex!important;
    align-items: start;
    flex-direction: row!important;

}

.jemek__cookies-info__legend {
    position: sticky;
    background-color: pink;
    height: 100dvh;
    top: 0;
    z-index: 1000;
    
}

.fixed-position {
    position: sticky;
    top:120px;
    background-color: pink;
    z-index: 1000;
   
}

@media screen and (min-width: 1300px) {
    .jemek__cookies-info__h3 {
        font-size: var(--step-2)!important;

    }
}