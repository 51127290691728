.jemek__contactpage-header-row {
    padding-top: 3.75rem;
}



.jemek__contactpage-column-contents {
    display: flex;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 2%;
    flex-direction: column;
}

.jemek__contactpage-column-contents p {
    padding-bottom: 1.5625rem;
    line-height: 2.25rem;
}

.jemek__contactpage-column-contents b {
    padding-bottom: 0.625rem;
}

.jemek__contactpage-locations {
    padding-top: 3rem;
}

.jemek__contactpage-location-header {
    padding-bottom: 1.875rem;
}

.map-responsive {
    overflow: hidden;
    padding-bottom: 100%;
    position: relative;
    height: 0;
}

.map-responsive iframe {
    left: 0;
    top: 0;
    border: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.fs-20 {
    font-size: 1.25rem;
}

.fs-16 {
    font-size: 1rem;
    line-height: 1.875rem;
}

.jemek__location-text {
    display: flex;
    flex-direction: column;
}

.jemek__contactpage-findus {
    padding-top: 1rem;
    padding-bottom: 3rem;
}

.jemek__contactpage-findus-header {
    padding-bottom: 1%;
}

.jemek__contactpage-findus-header h2 {
    font-size: 3rem;
}

.jemek__contactpage-finds-items {
    width: 50%;
}

.jemek__contactpage-location {
    display: flex;
    flex-direction: row;
    padding-right: 3.125rem;
}

.icon-wrapper {
    fill: blue;
}

.jemek__contactpage-location > svg {
    margin-right: 0.625rem;
}

.jemek__contactpage-location-text {
    margin-bottom: 0.3125rem;
}

.jemek__contactpage-location-texttwo {
    color: #999999;
    font-size: 0.875rem;
}

.jemek__contactpage-location-wrapper {
    display: flex;
    flex-direction: row;
}

.jemek__contactpage-wrapper h2, 
.jemek__contactpage-wrapper h3, 
.jemek__contactpage-wrapper b {
    color:#241d1d;
    margin-top: 2.5%;
 
  }


@media screen and (max-width: 480px) {
    .jemek__contactpage-header-row {
        padding-top: 10%;
        flex-direction: column;
    }
    .jemek__contactpage-column-contents {
        display: flex;
        padding-top: 1.075rem;
        padding-bottom: 0.325rem;
        padding-left: 0.625rem;
        flex-direction: column;
    }

    .jemek__contactpage-column-contents b {
        padding-bottom: 0.1rem;
        font-size: 0.8rem;
    }

    .table td {
        font-size: 0.6rem;
    }

    .jemek__contactpage-column-contents p {
        padding-bottom: 0.2rem;
        line-height: 2.25rem;
        font-size: 0.6rem;
    }

    .jemek__contactpage-location-wrapper {
        display: flex;
        flex-direction: column;
        gap:1.8rem;
    }

    .jemek__contactpage-location-wrapper {
        margin-bottom: 1rem;
    }

    .jemek__contactpage-wrapper h2, 
    .jemek__contactpage-wrapper h3, 
    .jemek__contactpage-wrapper b {
        color: #241d1d;
        font-size: 0.9rem;
    }

    .jemek__contactpage-location-texttwo {
        color: #999999;
        font-size: 0.6rem;
    }

    .jemek__contactpage-location-text {
        margin-bottom: 0.3125rem;
        font-size: 0.7rem;
    }

    .jemek__contactpage-location {
        padding-bottom: 0.5rem;
      }



    .jemek__contactpage-findus-header h2 {
        font-size: 1rem;
    }

    .jemek__contactpage-finds-items h3 {
        font-size: 1rem;
    }

    .jemek__contactpage-finds-items p {
        width: 200%;
        font-size: 0.6rem;
    }

    .jemek__contactpage-locations {
        padding-top: 0.625rem;
    }


.jemek__contactpage-findus {
    padding-top: 1.475rem;
}


}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .jemek__contactpage-location-wrapper {
        display: flex;
        flex-direction: column;
      }

      .jemek__contactpage-location {
        padding-bottom: 2rem;
      }

      .jemek__contactpage-finds-items p {
        width: 200%;
        font-size: 0.8rem;
    }
}

@media screen and (min-width: 1024px) {
    .jemek__contactpage-finds-items h3 {
        font-size: var(--step-2)!important;
    }

    .jemek__contactpage-finds-items p {
        font-size: var(--step-0)!important;
    }

    .jemek__contactpage-wrapper h2 {
        font-size: var(--step-3)!important;
    }

    .jemek__contactpage-column-contents p {
        font-size: var(--step-0)!important;
    }
}
