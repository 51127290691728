.jemek__whoweare-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 15%;
    padding-bottom: 10%;
    background-image: url(/home/ghost/Documents/codingprojs/react_app_jemek/frontend_react/src/assets/abstract-blue.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.jemek__whoweare-header-container-text {
    display: flex!important;
    align-items: center!important;
    flex-direction: column!important;
    justify-content: center!important;
}

.jemek__whoweare-header-container-text h1 {
    color: rgb(255, 255, 255)!important;

}

.jemek__whoweare-contentone {
    padding-top: 3.75rem;
    display: flex!important;
    flex-direction: column;
}

.jemek__whoweare-contentone-image {
    transition: transform 0.5s ease-in-out;
}

.jemek__whoweare-contentone-image:hover {
    transform: scale(1.07);
}

.jemek__whoweare-contentone-image img {
    width: 100%;
    height: 43rem;
    object-fit: cover;
    padding: 2%;
}

.jemek__whoweare-contentone-text_one {
    display: flex;
    align-items: center;
    justify-content: center;
}

.jemek__whoweare-contentone-button button {
    background: #02060a!important;
    border: 1px solid #0d0d0e!important;
    border-radius: 1px!important;
    color: white!important;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1em!important;
    transition: 0.5s;
    margin-top: 2%!important;
}

.jemek__whoweare-contentone-button button:hover {

    background-color: #5997e9!important;
    border: 1px solid #5997e9!important;
    border-radius: 1px!important;
    color: whitesmoke!important;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1em!important;
}

.jemek__whoweare-contentone-text_two {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 3.75rem;
}

.jemek__whoweare-sectiontwo {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #04040408;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
}

.jemek__whoweare-sectiontwo-container {
    max-width: 1500px!important;
}

.jemek__whoweare-contentone-text_three {
    display: flex!important;
    align-items: center!important;
    background-color: #f8f9fa63;
    width: 100%;
    color: white;
    margin: 0!important;
    /* padding-top: 2.45rem; */
    /* padding-bottom: 4.2rem; */
}

.jemek__whoweare-contentone-text_four {
    padding-top: 1.25rem;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    background-color: #192E47;
    width: 100%;
    color: white;
    padding-top: 7rem;
    padding-bottom: 7rem;
    font-size: 1.5rem;
    max-width: 1920px;
    margin: 0 auto;
}

.jemek__whoweare-contentone-text_three-itemone {
    padding-right: 1.5rem!important;
}

.jemek__whoweare-contentone-text_three-item-two {
    padding-left: 1.5rem!important;
    padding-right:0rem!important;
}

.jemek__whoweare-contentone-text_five {
    padding-top: 4rem;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    flex-direction: column;
}

.jemek__whoweare-contentone-text_six {
    padding-top: 1.25rem;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    flex-direction: column;
}

.jemek__whoweare-contentone-text_seven {
    padding-top: 0.625rem;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    flex-direction: column;
}

.jemek__whoweare-contentone-text_eight {
    padding-top: 4rem;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    flex-direction: column;
}

.jemek__whoweare-contentone-text_nine {
    padding-top: 0;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    flex-direction: column;
}

.jemek__whoweare-contentone-text_ten {
    padding-top: 1.25rem;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    flex-direction: column;
    padding-bottom: 1.25rem;
}

.jemek__whoweare-contentone-text_eleven {
    padding-top: 0;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    flex-direction: column;
}

.jemek__whoweare-contentone-text_twelve {
    padding-top: 0.3125rem;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 5rem;
}

.jemek__whoweare-contentone-text_one p {
    font-size: 1.5rem;
    width: 80%;
    padding-bottom: 1.875rem;
}

.jemek__whoweare-contentone-text_two p {
    font-size: 1.25rem;
    font-weight: 300;
    width: 70%;
}

.jemek__whoweare-contentone-text_three p {
    font-size: 1.375rem!important;
    font-weight: 400!important;
    width: 94%!important;
    line-height: 2rem;
    margin-right: auto;
    background-color: #6c757d0f;
    padding: 1.875rem;
    color: #000000;
}

.jemek__whoweare-contentone-text_three h2 {
    font-size: 5.275rem!important;
    font-weight: 300!important;
    width: 100%!important;
    margin-left: auto;

    margin-right: auto;
    color: #5997e9;
}



.jemek__content-section__image {
    width: 100%;
    height: 35rem;
    padding: 1rem;
    object-fit: cover;
}

.jemek__content-section__image-container {
    width: 100%;
    padding: 2.5rem;
}

.jemek__whoweare-contentone-text_four p {
    font-size: 1.3rem!important;
    font-weight: 300;
    line-height: 3rem;
    width: 60%;
}


.jemek__whoweare-container-five-image {
    max-width: 100% !important;
    display: flex!important;
    padding-bottom: 1.25rem;
    padding-top: 0.625rem;
    width: 100%!important;
    height: 32rem !important;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.jemek__whoweare-contentone-text_five h3 {
    font-weight: 300!important;
    color: #241d1d;
}

.jemek__whoweare-contentone-text_five p {
    font-size: 1.25rem!important;
    font-weight: 300;
    width: 70%;
    line-height: 2.1875rem;
}

.jemek__whoweare-contentone-text_six p {
    font-size: 1.125rem!important;
    font-weight: 300;
    width: 60%;
}

.jemek__whoweare-contentone-text_seven p {
    font-size: 1.5rem!important;
    font-weight: 300;
    width: 60%;
}

.jemek__whoweare-contentone-text_eight h3 {
    font-weight: 300!important;
    color: #241d1d;
}

.jemek__whoweare-contentone-text_eight p {
    font-size: 1.25rem!important;
    font-weight: 300;
    width: 90%;
    line-height: 2.1875rem;
}

.jemek__whoweare-contentone-text_ten p {
    font-size: 1.25rem!important;
    font-weight: 300;
    width: 60%;
}

.jemek__whoweare-contentone-text_eleven p {
    font-size: 1.25rem!important;
    font-weight: 300;
    width: 70%;
    line-height: 2.5rem;
}

.jemek__whoweare-contentone-text_twelve a {

        color: #316098;
        transition: 0.5s;
}

@media screen and (max-width: 480px) {
    .jemek__whoweare-header {
        margin-left: auto;
        margin-right: auto;
        padding-top: 20%;
        padding-bottom: 15%;
        width: 100%;
    }
    .jemek__whoweare-header-container-text h1 {
        color: rgb(255, 255, 255)!important;

    }

    .jemek__whoweare-contentone {
        padding-top: 1rem;
    }

    .jemek__whoweare-contentone-text_one p {
        font-size: 0.8rem !important;
        width: 90%;
        padding-bottom: 0.5rem;
    }
    .jemek__whoweare-contentone-image img {
        width: 100%;
        height: 23rem;
    }

    .jemek__whoweare-contentone-text_two {
        padding-bottom: 1.5rem;
    }

    .jemek__whoweare-contentone-text_three-item-two {
        padding-left: 1rem!important;
        padding-right: 1rem!important;
        padding-top: 5%;
    }

    .jemek__whoweare-contentone-text_two p {
        font-size: 0.7em !important;
        width: 70%;
    }

    .jemek__whoweare-contentone-text_three {
        flex-direction: column;
        width: 100% !important;
        margin: 0 !important;
        padding-bottom: 0.4rem;
    }

    .jemek__whoweare-contentone-text_four {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .jemek__whoweare-sectiontwo-container {
        display: flex;
        justify-content: space-between;
        max-width: 100%;
        padding: 0;
    }

    .jemek__whoweare-sectiontwo {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2rem 0;
        background-color: #04040408;
    }

    .jemek__whoweare-contentone-text_three-itemone {
        width: 100%!important;
        padding-right: 0.3rem!important;
    }

    .jemek__whoweare-contentone-button button {
        padding: 2%;
        font-size: 0.6rem!important;
    }

    .jemek__whoweare-contentone-text_three-itemtwo {
        width: 100%!important;
    }

    .jemek__whoweare-contentone-text_three h2 {
        font-size: 1.5rem!important;
        font-weight: 400!important;
    }

    .jemek__whoweare-contentone-text_three p {
        font-size: 0.8rem!important;
        font-weight: 400!important;
        width: 100%!important;

        margin-top: 0.4375rem;
        line-height: 2rem;
        margin-right: auto;
        background-color: #6c757d0f;
        padding: 0.375rem;
        color: #000000;
    }

    .jemek__whoweare-contentone-text_eight h3,
    .jemek__whoweare-contentone-text_five h3 {
        font-weight: 400!important;
    }

    .jemek__whoweare-contentone-text_four p {
        font-size: 0.9rem!important;
        font-weight: 300;
        line-height: 1.5rem;
        width: 90%;
    }

    .jemek__whoweare-container-five-image {
        height: 17rem !important;
    }


.jemek__content-section__image-container {
    width: 100%;
    padding: 1rem;
}

    .jemek__content-section__image {
        width: 100%;
        height: 15rem;
        padding: 1rem;
        object-fit: cover;
    }


    .jemek__whoweare-contentone-text_five {
        padding-top: 1rem;
    }

    .jemek__whoweare-contentone-text_five h2 {
        font-size: 1.375rem!important;
        margin-left: 0rem;
        width: 100%!important;
    }

    .jemek__whoweare-contentone-text_five p {
        font-size: 0.9rem!important;
        width: 89.7%;

        line-height: 1.5rem;
    }
    .jemek__whoweare-contentone-text_seven p {
        font-size: 0.9rem!important;
        font-weight: 300;
        width: 80%;
        line-height: 1.5rem;
    }

    .jemek__whoweare-contentone-text_seven {
        padding-top: 0.225rem;

    }

    .jemek__whoweare-contentone-text_eight {
        padding-top: 1rem;
    }

    .jemek__whoweare-contentone-text_eight h2 {
        font-size: 1.375rem!important;
        font-weight: 300!important;
        width: 80%!important;
        margin-left: 0rem;
        color: #2c4d78;
    }

    .jemek__whoweare-contentone-text_eight p {
        font-size: 0.9rem!important;
        font-weight: 300;
        width: 89.7%;
        line-height: 1.5rem;

    }

    .jemek__whoweare-contentone-text_ten {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }
    
    .jemek__whoweare-contentone-text_ten p {
        font-size: 0.9rem!important;
        width: 80%;
        line-height: 1.5rem;
    }

    .jemek__whoweare-contentone-text_eleven p {
        font-size: 0.9rem!important;
        font-weight: 300;
        width: 89.7%;
        line-height: 1.5rem;
    }

    .jemek__whoweare-contentone-text_twelve {
        font-size: 0.8rem!important;
        padding-bottom: 2rem;
    }
}
  
  @media screen and (min-width: 481px) and (max-width: 768px) {

    .jemek__whoweare-sectionthree-container {
        max-width: 100%!important;
    }



    .jemek__content-section__image-container {
        width: 100%;
        padding: 1.5rem;
    }

    .jemek__content-section__image {
        height:25rem;
    }

    .jemek__whoweare-header-container-text h1 {
        font-size: 1.5rem!important;
    }

    .jemek__whoweare-header {

        padding-top: 12rem;
        padding-bottom: 11rem;

    }

    .jemek__whoweare-contentone {
        padding-top: 3rem;
    }


.jemek__whoweare-contentone-text_one p {
    font-size: 1rem;
}


.jemek__whoweare-contentone-text_one p {
    padding-bottom: 0.5rem;
}

.jemek__whoweare-contentone-text_two p {
    font-size: 0.8rem!important;
}


    .jemek__whoweare-sectiontwo {
        padding: 2rem 1rem;
    }


    .jemek__whoweare-sectiontwo-container {
        max-width: 100%!important;
        padding-left: 0rem!important;
        padding-right: 0rem!important;
    }

    .jemek__whoweare-contentone-image img {
        width: 100%;
        height: 25rem;
    }

    .jemek__whoweare-contentone-text_three-item {
        padding-left: 0rem!important;
        padding-right: 0rem!important;
    }

    .jemek__whoweare-contentone-text_three h2 {
        font-size: 2rem!important;

    }

    .jemek__whoweare-contentone-text_three p {
        font-size: 0.8rem!important;
        width: 100%!important;
        line-height: 1.3rem;
        padding: 0.8rem;
    }


    .jemek__whoweare-contentone-text_three-item-two {
        padding-left: 1rem!important;

    }

    .jemek__whoweare-contentone-button button {
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 0.6em!important;
    }

    .jemek__whoweare-contentone-text_four p {
        font-size: 0.8rem!important;
        line-height: 2rem;
        width: 80%;
        margin-bottom: 0rem!important;
    }


    .jemek__whoweare-contentone-text_five {
        padding-top: 2rem;
    }

    .jemek__whoweare-contentone-text_five h2 {
        font-size: 1.5rem!important;
        margin-left: 0rem;
        width: 70%!important;
    }

    .jemek__whoweare-contentone-text_five p {
        font-size: 0.8rem!important;
        width: 90%;
        line-height: 1.5rem;
        margin-bottom: 0.5rem;
    }

    .jemek__whoweare-contentone-text_seven p {
        font-size: 0.9rem!important;
        width: 90%;
    }

    .jemek__whoweare-contentone-text_eight {
        padding-top: 1.5rem;
    }

    .jemek__whoweare-contentone-text_eight h2 {
        font-size: 1.5rem!important;
        margin-left: 0rem!important;
        width: 80%!important;
    }

    .jemek__whoweare-contentone-text_eight p {
        font-size: 0.8rem!important;
        line-height: 1.5rem;
    }

    .jemek__whoweare-contentone-text_ten {
        padding-top: 0rem;
        padding-bottom: 0rem;

    }

    .jemek__whoweare-contentone-text_ten p {
        font-size: 0.9rem!important;
        width: 90%;
    }

    .jemek__whoweare-contentone-text_eleven p {
        font-size: 0.8rem!important;
        font-weight: 300;
        width: 90%;
        line-height: 1.5rem;
    }

    .jemek__whoweare-contentone-text_twelve {
        padding-bottom: 4rem;
    }

    .jemek__whoweare-contentone-text_twelve a {
        font-size: 0.8rem;
    }
  }
  
  @media screen and (min-width: 769px) and (max-width: 1024px) {

    .jemek__whoweare-header {
        padding-top: 14rem;
        padding-bottom: 13rem;
    }

    .jemek__whoweare-header-container-text h1 {
        font-size: 2rem!important;
    }



    .jemek__whoweare-contentone-text_one p {
        font-size: 1.2rem;
        width: 90%;
        padding-bottom: 1rem;
    }

    .jemek__whoweare-contentone-text_two p {
        font-size: 0.9rem;
        font-weight: 300;
        width: 70%;
    }

    .jemek__whoweare-contentone-image img {
        width: 100%;
        height: 32rem;
        object-fit: cover;
    }

    .jemek__whoweare-contentone-text_three h2 {
        font-size: 4rem!important;
    }
    .jemek__whoweare-contentone-text_three p {
        font-size: 1rem!important;

        line-height: 2.025rem;

        padding: 1.675rem;

    }

    .jemek__whoweare-contentone-text_four p {
        width: 90%;
        font-size: 1rem!important;

    line-height: 2.5rem;
    }
    
  }
  
@media screen and (min-width: 1320px) {
    .jemek__whoweare-contentone-text_three p {
        font-size: var(--step-1)!important;
        line-height: 3rem;
    }
}