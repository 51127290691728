

/* @link https://utopia.fyi/type/calculator?c=320,16,1.2,1920,20,1.333,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */


:root {
  /* Step -3: 5.79px → 8.19px */
  --step--3: clamp(0.36rem, calc(0.33rem + 0.15vw), 0.51rem);

  /* Step -2: 6.94px → 10.24px */
  --step--2: clamp(0.43rem, calc(0.39rem + 0.21vw), 0.64rem);

  /* Step -1: 8.33px → 12.80px */
  --step--1: clamp(0.52rem, calc(0.46rem + 0.28vw), 0.80rem);

  /* Step 0: 10.00px → 16.00px */
  --step-0: clamp(0.63rem, calc(0.55rem + 0.38vw), 1.00rem);

  /* Step 1: 12.00px → 20.00px */
  --step-1: clamp(0.75rem, calc(0.65rem + 0.50vw), 1.25rem);

  /* Step 2: 14.40px → 25.00px */
  --step-2: clamp(0.90rem, calc(0.77rem + 0.66vw), 1.56rem);

  /* Step 3: 17.28px → 31.25px */
  --step-3: clamp(1.08rem, calc(0.91rem + 0.87vw), 1.95rem);

  /* Step 4: 20.74px → 39.06px */
  --step-4: clamp(1.30rem, calc(1.07rem + 1.15vw), 2.44rem);

  /* Step 5: 24.88px → 48.83px */
  --step-5: clamp(1.56rem, calc(1.26rem + 1.50vw), 3.05rem);

  /* Step 6: 29.86px → 61.04px */
  --step-6: clamp(1.87rem, calc(1.48rem + 1.95vw), 3.82rem);

  /* Step 7: 35.83px → 76.29px */
  --step-7: clamp(2.24rem, calc(1.73rem + 2.53vw), 4.77rem);
}
* {
  box-sizing:border-box;
  padding: 0;
  margin: 0;
  scroll-behavior:smooth;
}


.span-text-highlight {
  color: #5997e9;
}

.span-text-highlight-two {
  color: #316098;
}

h1 {
  font-size: var(--step-5)!important;
  font-weight: 400!important;
}

h2 {
  font-size: var(--step-4)!important;
}

h3 {
  font-size: var(--step-2)!important;
}

h4 {
  font-size: var(--step-2)!important;
}

h5 {
  font-size: var(--step-1)!important;
}

p {
  font-size: var(--step-0)!important;
  margin-bottom: 0.5rem!important;
  margin-top: 0.5rem!important;
  transition: 0.5s;
}

th, td {
  font-size: var(--step-0)!important;
}


.fine-print {
  color: #4979b4;
  transition: 0.5s;
}

.fine-print:hover {
  color: #5997e9;
}



body {
  background: var(--color-bg)!important;

}

a {
  color: none!important;
  text-decoration: none !important;

}


ol, ul {
  padding-left: 0rem !important;
}

li {
  text-decoration: none;
  list-style-type: circle;
  margin-top: 1%;
  font-size: var(--step--1)!important;
}

.row {
  flex-wrap: nowrap !important;
}

.gradient__bg {
  
    /* ff 3.6+ */
    background:-moz-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%, rgb(255, 255, 255) 100%); 

    /* safari 5.1+,chrome 10+ */
    background:-webkit-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%, rgb(255, 255, 255) 100%);

    /* opera 11.10+ */
    background:-o-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%, rgb(255, 255, 255) 100%);

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ee82ee', endColorstr='#FFFF00', GradientType=0 );

    /* ie 10+ */
    background:-ms-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%, rgb(255, 255, 255) 100%);

    /* global 94%+ browsers support */
    background:linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%, rgb(255, 255, 255) 100%);
  
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  --webkit-background-color: text;
  --webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 2rem 2rem;

}

.img {
  max-width: 100%;
  height: auto;
}


.section__margin {
  margin: 2rem 4rem;

}

.row>* {
  flex-shrink: 1!important;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  /*
  
  .section__padding {
    padding: 2rem;
  }
  */

  .section__margin {
    margin: 2rem;
  }
}

@media screen and (max-width: 480px) {

  p {
    font-size: var(--step-1)!important;
    margin-bottom: 0.5rem!important;
    margin-top: 0.5rem!important;
    transition: 0.5s;
  }

  h3 {
    font-size: var(--step-3)!important;
    margin-bottom: 0rem!important;
  }

  h4 {
    font-size: var(--step-2)!important
  }



  h1 {
    font-size: var(--step-4)!important;
  }

  button {
    font-size: var(--step-0)!important;
  }



  .section__padding {
    padding: 0.5rem 0.5rem;
  }

  .section__margin {
    margin: 2rem 1rem;
  }
}


@media screen and (min-width: 481px) and (max-width: 768px) { 
  p {
    font-size: var(--step-1)!important;
  }

  button {
    font-size: var(--step-0)!important;
  }

  h4 {
    font-size: var(--step-1)!important
  }
}



@media screen and (min-width: 769px)  {
  h3 {
    font-size: var(--step-3)!important;
  }

  h4 {
    font-size: var(--step-2)!important;
  }

  p {
    font-size: var(--step-1)!important;
  }

  li {
    font-size: var(--step--1)!important;
  }
  th, td {
    font-size: var(--step-0)!important;
  }

  button {
    font-size: var(--step-0)!important;
  }



}

@media screen and (min-width:1280px) {
  h1 {
    font-size: var(--step-5)!important;
    font-weight: 400!important;

  }
}



