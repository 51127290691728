.jemek__mobile-nav__container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    transition: 0.5s;
    animation: fadeIn 0.3s ease-out both;
}


button.jemek__mobile-nav__button {
    padding: 0rem;
    text-decoration: none;
    background: none;
    border: none;
    animation: fadeIn 0.3s ease-out both;
    display: flex;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid #f5f5f5;;
    margin-bottom: 5px;
    height: 50px;
    color: #241d1d!important;
    font-size: 0.9rem;
    transition: 0.5s;
}

/*
button.jemek__mobile-nav__button:hover {
    color: grey!important;
}

*/

/*
button.jemek__mobile-nav__button:hover:not(.submenu-open) {
    color: grey!important;
}
*/

.jemek__mobile-nav__main-menu,
.jemek__mobile-nav__about-us,
.jemek__mobile-nav__practice-areas,
.jemek__mobile-nav__immigration-and-asylum,
.jemek__mobile-nav__family,
.jemek__mobile-nav__child-care,
.jemek__mobile-nav__funding-your-case {
    transition: 0.5s;
}

.jemek__mobile-nav__back-button {
    font-size: var(--step--1)!important;
    color: grey;

}

.jemek__mobile-nav__back-button svg {
    font-size: var(--step--2)!important;
    margin-right: 0.4rem
}

.jemek__mobile-nav-iconright {
    position: absolute;
    right: 2rem;
    padding-right: 3px;
    vertical-align: center;
    
}

@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
  
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }


