.jemek__footer-lower {
    background-color: #b0d5fa00;

    display: flex;
    padding: 1rem; 

}


.jemek__footer-lower-container {

    align-items: center;
    width: 100%!important;
    max-width: 100%!important;    
    font-size: 1rem;
}

.jemek-bottom-footer-item__copyright p {
    color: #aaabad;
    margin: 0rem!important;
}


.jemek__footer-lower-row p, 
.jemek__footer-lower-row a , .jemek__footer-item {
    font-size: var(--step--1)!important
}

.jemek__footer-lower-column  {
    flex: 1 1!important;

    width: 100%!important;
    color: #999999;
    margin-top: 0.5rem;
    font-weight: bold;

    line-height: 1.6;
}


.jemek__footer-lower-items  {

    flex: 1!important;
    color: #999999;
    width: 100%;
    font-weight: bold;

    line-height: 1.6;
}

.jemek__footer-grid a {
    transition: 0.5s;
}

.jemek-bottom-footer-item__misc {
    flex-direction: row;
    display: flex;
    width: auto;
    justify-content: space-around;
}



@media screen and (max-width: 480px) {
    .jemek__footer-lower {
        padding: 0rem 1rem;
    }

    .jemek__footer-lower-container {

        width: 100%!important;
    }
    .jemek__footer-lower-row {
        display: flex;
        flex-direction: column;
        width: auto;
    }

    .jemek__footer-lower-row, .jemek__footer-item {
        font-size: 0.4rem;
    }

    .jemek__footer-lower-column {
        margin-left: 0!important;
    }

    .jemek__footer-lower-items {
        justify-content: space-between;
        flex-direction: column;
        padding-bottom: 0.5rem;
        line-height: 1rem;
    }

    .jemek-bottom-footer-item__copyright p {
        margin-bottom: 0rem;

    }

    .jemek-bottom-footer-item__misc {
        flex-direction: column;
        display: flex;
        width: auto;
        justify-content: space-around;
    }
    
}

@media screen and (min-width:481px) and (max-width: 768px) {

    .jemek__footer-lower {
        padding: 0rem 1rem 1rem 1rem
    }


.jemek__footer-lower-row, .jemek__footer-item {
    font-size: 0.7rem;
    flex-direction: column;
}

.jemek-bottom-footer-item__copyright {
    padding-left: 0.5rem;
}

.jemek-bottom-footer-item__copyright p {
    margin-bottom: 0rem;
}

.jemek-bottom-footer-item__misc {
    padding-left: 0.5rem;
    line-height: 1.5rem;
    flex-direction: column;
    display: flex;
    width: auto;
    justify-content: space-around;
}



}


@media screen and (min-width:769px) and (max-width: 1024px) {


    .jemek__footer-lower {
        margin-top: 1rem;
        padding: 0rem 1.2rem;
        padding-bottom: 1rem;
    }

    .jemek__footer-lower-items {
        padding-left: 1.5rem!important;
        line-height: 1.5rem;
    }

    .jemek__footer-lower-column {
        padding-left: 1.5rem!important;
    }

    .jemek__footer-lower-row, .jemek__footer-item {
        font-size: 0.7rem;
        flex-direction: column;
    }

    .jemek-bottom-footer-item__copyright p {
        line-height: 1.5rem;
        margin-bottom: 0rem;
    }

    .jemek-bottom-footer-item__misc {
        flex-direction: column;
        display: flex;
        width: auto;
        justify-content: space-around;
    }

}

@media screen and (max-width: 950px) {

}

@media screen and (max-width: 990px) {

}

@media screen and (min-width: 1024px) {
    .jemek-bottom-footer-item__copyright p {
        font-size: var(--step--1)!important;
    }
    
}