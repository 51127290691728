.jemek__fundingyourcase-itemone-section {
    padding-top: 3.125rem;
}

.jemek__fundingyourcase-itemone-section,
.jemek__fundingyourcase-itemtwo-section {
    margin: 0 auto;
    max-width: 1100px;
}

.jemek__fundingyourcase-image img {
    width: 100%;
    height: 24rem;
    background-size: cover;
}

.fundingyourcase-text-highlight {
    color: #5997e9;
}

.jemek__fundingyourcase-column-cta p {
    color: #316098;
}

.jemek__fundingyourcase-column-cta p:hover {
    color: #7399c6;
}

.jemek__fundingyourcase-column-items {
    display: flex;
    flex-direction: column;
    margin-top: 2.5rem;
}

.jemek__fundingyourcase-column-text p{
    line-height: 1.5;
}

.jemek__fundingyourcase-columnone_left,
.jemek__fundingyourcase-columntwo_right {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.jemek__fundingyourcase-columntwo_right {
    padding-left: 3.125rem!important;
}

/*
.jemek__fundingyourcase-columnone_left {
    padding: 2rem!important;
}

*/

.jemek__fundingyourcase-columntwo_image.col-xl-6.col-xl-6.mb-4 {
    margin-left: 2.375rem!important;
}

.jemek__legalservicescard {

    background-color: #1e5295;
    padding-top: 9%;
    padding-bottom: 9%;
}

.jemek__legalservicescard-header h3 {
    color: whitesmoke;
}

.jemek__legalservicescard-text p {
    color: whitesmoke;
}


.jemek__legalservicescard-button button {
    background-color: #000000!important;
    color: rgb(247, 247, 247)!important;
    border: 0.05rem solid #000000;
    padding: 0.5rem;
    transition: 0.5s;
}

.jemek__legalservicescard-button button:hover {
    background-color: #5997e9!important;
    color: whitesmoke!important;
    border-color: #5997e9!important;

}

@media screen and (max-width: 480px) {


  .jemek__fundingyourcase-itemone-section {
    padding-top: 0.5rem;
}

.jemek__fundingyourcase-column-items {
    margin-top: 0rem;
}


  .jemek__fundingyourcase-itemone-row 
 {
    display: flex!important;
    flex-direction: column!important;
    width: 100% !important;
    margin: 0 !important;
    object-fit: cover;
  }

  .jemek__fundingyourcase-itemtwo-row {
    display: flex!important;
    flex-direction: column-reverse!important;
    width: 100% !important;
    margin: 0 !important;
    object-fit: cover;
  }

  .jemek__fundingyourcase-image img {
    height: 20em !important;
    width: 100%;
    margin-bottom: 0.5rem!important;
}
.jemek__fundingyourcase-column-header h2 {
    font-size: 0.9rem;
    color: #2c4d78;
    font-weight: 300;
}

    .jemek__fundingyourcase-column-text p {
        width: 100%;
        font-size: 0.4rem;
    }

    .jemek__fundingyourcase-columnone_left {
        padding-right: 0.5rem!important;
        padding-left: 0.5rem!important;
    }

    .jemek__fundingyourcase-columntwo_right {
        padding-left: 1rem!important;
    }

    .jemek__fundingyourcase-column-cta p{
        font-size: 0.4rem;
    }

    .jemek__fundingyourcase-columntwo_image.col-xl-6.col-xl-6.mb-4 {
        margin-left: 0rem!important;
        padding-left: 0.5rem!important;
        padding-right: 0.5srem!important;
    }

    .jemek__legalservicescard-header h2 {
        font-size: 1rem;
        color: #000000;
        font-weight: 400;
    }

    .jemek__legalservicescard-text p {
        font-size: 0.6rem;
        width: 85%;
        margin-left: auto;
        margin-right: auto;
        line-height: 1.5rem;
    }

    .jemek__legalservicescard-button button {
        border-radius: 1px;
        font-size: 0.5rem;
    }

  }
  
  @media screen and (min-width: 481px) and (max-width: 768px) {
    .jemek__fundingyourcase-columnone_left {
        padding-right: 0.5rem!important;
        padding-left: 0.5rem!important;
    }

    .jemek__fundingyourcase-columntwo_right {
        padding-left: 1rem!important;
    }

    .jemek__fundingyourcase-image img {
        width: 100%;
        height: 16rem;
        background-size: cover;
    }

    .jemek__fundingyourcase-column-header h2 {
        font-size: 1.5rem;
    }

    .jemek__fundingyourcase-columntwo_image.col-xl-6.col-xl-6.mb-4 {
        margin-left: 1rem!important;
    }

    .jemek__fundingyourcase-column-text p {
        font-size: 0.8rem;
    }

    .jemek__fundingyourcase-column-cta p {
        font-size: 0.7rem;
    }

  }
  
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .jemek__fundingyourcase-columntwo_image.col-xl-6.col-xl-6.mb-4 {

        padding: 2rem;
    }

    .jemek__fundingyourcase-columnone_image.col-xl-6.col-xl-6.mb-4 {

        padding: 2rem;
    }

    .jemek__fundingyourcase-image img {

        height: 20rem;

    }
  
  }

  @media screen and (min-width: 1025px) {
    .jemek__fundingyourcase-image img {
        width: 100%;
        height: 27rem;
        background-size: cover;
    }
  }


  @media screen and (min-width: 1320px) {

    .jemek__fundingyourcase-columntwo_image {
        margin-left: 2.375rem!important;
        padding: 1.5rem!important;
    }

    .jemek__fundingyourcase-columnone_image {
        padding: 1.5rem!important;
    }

    .jemek__fundingyourcase-column-cta p {
        font-size: var(--step-0)!important;
    }

    .jemek__fundingyourcase-column-text p{
        font-size: var(--step-0)!important;
    }

    .jemek__legalservicescard-text p {
        font-size: var(--step-0)!important;
        margin-top: 1.5rem!important;
        margin-bottom:1.5rem!important;
    }
  }

