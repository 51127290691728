.jemek__complaints__section-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 20%;
    padding-bottom: 15%;
    background-color: #070a37;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.jemek__complaints__content {
    padding-top: 2rem;
    padding-left: 7%!important;
    padding-right: 7%!important;
}

.jemek__complaints__body-container {

    flex-direction: row;
    width: 100%!important;

}

.jemek__complaints__body-row {
    display: flex!important;
    align-items: start;
    flex-direction: row!important;

}
.jemek__complaints__h3 {
    margin-top: 2%!important;
}

.jemek__complaints__h4 {
    margin-top: 1.5%!important;
}

.jemek__complaints__link {
    transition: 0.5s;
    color: #316098;
}

.jemek__complaints__link:hover {
    color: #7399c6;
}

.jemek__complaints__legend {
    position: sticky;
    background-color: pink;
    height: 100dvh;
    top: 0;
    z-index: 1000;
    
}

.jemek__complaints__contact-wrapper p {
    margin-bottom: 0rem!important;
}

.jemek__complaints__sra-contact p {
    margin-bottom: 0rem!important;
}

@media screen and (min-width:1300px) {
    .jemek__complaints__h3 {
        font-size: var(--step-2);
    }
}