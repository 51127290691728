.jemek__templatetwo-header {
    width:100%;
    background-image: url(/home/ghost/Documents/codingprojs/react_app_jemek/frontend_react/src/assets/abstract-blue.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 15%;
    padding-bottom: 10%;
    color: white;
}




.jemek__templatetwo-content-one-textbox p {

  background-color: #fbfbfb;
    padding: 2rem;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 18px;
    width: 90%;
    line-height: 42px;
    font-weight: 300;
    color: rgb(0, 0, 0);
    margin-top: -50px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: -2.8rem;
    left: 0;
    right: 0;
    bottom: 0;
}


.jemek__templatetwo-content-two-main h2 {
    font-size: 37px!important;
    font-weight: 300!important;
    width: 60%!important;
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px;
    padding-bottom: 1px;

    color: #2c4d78; 
}

.jemek__templatetwo-content-two-main p {
    font-size: 19px!important;
    font-weight: 300;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    line-height: 38px;
}

.jemek__templatetwo-content-two-text_one-sub h5 {
    font-weight: 400;
    font-size: 20px;
    color: #041833;
    padding-bottom: 30px;
    padding-top: 30px;
}

.jemek__templatetwo-content-two-text_one-sub p {

  font-size: 22px!important;
  margin-left: auto;
  margin-right: auto;
  width: 55%;

  
}


.jemek__templatetwo-content-two-text-three_wrapper {
    background-color: #192E47;
    width: 100%;
margin-top:40px;
margin-bottom: 40px;
padding-top: 20px;
padding-bottom: 20px;
}

.jemek__templatetwo-content-two-text_three {
    padding: 50px;
    margin: 0 auto;
    text-align: center;
    color: white;
  }


.jemek__templatetwo-content-two-text_three h2 {
    padding-bottom: 40px;
    color: #5997e9;
  }
.jemek__templatetwo-content-two-text_three p {
    margin-left:auto!important;
    margin-right: auto!important;
    width:60%!important;
  }


  .jemek__templatetwo-content-text-important p {
    font-size: 22px!important;
    margin-left: auto;
    margin-right: auto;
    width: 65%;
  }

  @media screen and (max-width: 480px) {
    .jemek__templatetwo-content-one-textbox p {
      padding: 3.508333%;
      padding-left: 8%;
      padding-right: 8%;
      font-size: 0.5rem;
      width: 90%;
      line-height: 1.5rem;
      margin-top: 1.2rem!important;
  }
  
  }
  
  @media screen and (min-width: 481px) and (max-width: 768px) {
    .jemek__templatetwo-content-one-textbox p {
      padding: 3.508333%;
      padding-left: 12%;
      padding-right: 12%;
      font-size: 0.8rem;
      width: 100%;
      line-height: 1.5rem;
      margin-top: -4rem;
    }

    .jemek__fundingyourcase-itemone-section {
      padding-top: 1rem;
  }
  }
  
  @media screen and (min-width: 1300px) {
    .jemek__templatetwo-content-one-textbox p {
  
      font-size: var(--step-0)!important;
  
  }
  }