
.jemek__legalservices-explore_wrapper {
  background-color: #5997e9;
  margin-top: 3rem;
}
.jemek__legalservices-explore-container a {
  color: black!important;
}

.jemek__legalservices-item-wrapper {
  padding: 1.875rem;
  border-radius: 0.0625rem;
  margin-top: 2.5rem;
  border-top: 0.0625rem solid #e3e3e3;
  border-bottom: 0.0625rem solid #e3e3e3;
}

.jemek__legalservices-item-wrapper-two {
  padding: 1.875rem!important;
  border-radius: 0.0625rem;
  border-bottom: 0.0625rem solid #e3e3e3;
}

.jemek__legalservices-dropdown_container {
  padding-top: 1%;

  animation: scale-in-ver-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.jemek__legalservices-links-header {
  padding-top: 4.375rem;
  padding-bottom: 1.25rem;
}

.jemek__legalservices-links-header h2 {
  color: #fff;
  font-weight: 400;
}

.jemek__legalservices-text {
  display: flex;
  padding: 1.25rem;
  align-items: center;
  transition: 0.5s;
  justify-content: space-between;
  width: 100%;
  color: #fff;
  font-size: 1.5rem;
  letter-spacing: 0.2rem;

}

.jemek__legalservices-text:hover {
  color: #0b2547!important;
}

span.jemek__legalservices-text:hover .jemek__legalservices-icon {
  color: #0b2547!important;
}

.jemek__legalservices-dropdown_content p {
  margin-left: auto;
  margin-right: auto;
  width: 98.5%;
  color: #edecec;
}

.jemek__legalservices-item-9 {
  padding-bottom: 4.375rem;
}

.jemek__legalservices-icon {
  font-size: 1.75rem;
  flex-shrink: 0;
  margin-left: 0.625rem;
  border-radius: 1.875rem;
  color: white!important;
  background-color: #ffffff59;
  padding: 0.3125rem;
  transition: 0.5s;
}


.jemek__legal-services-explore__submenu-hovered {
  animation: scale-in-ver-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.jemek__legal-services-explore__submenu-hovered:hover {
   animation: scale-in-ver-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

span.jemek__legalservices-text:hover .jemek__legalservices-icon:hover {
  background-color: #d5dfec;

}





@-webkit-keyframes focus-in-expand {
    0% {
      letter-spacing: -0.5em;
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes focus-in-expand {
    0% {
      letter-spacing: -0.5em;
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }

  @keyframes scale-out-ver-top {
    0% {
      transform: scaleY(1);
      transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      transform: scaleY(0);
      transform-origin: 100% 0%;
      opacity: 1;
    }
  }

 
  .focus-in-expand {
	-webkit-animation: focus-in-expand 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: focus-in-expand 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}



@media screen and (max-width: 480px) {


.jemek__legalservices-item-wrapper {
  padding: 1rem;
  margin-top: 1rem;

}


  .jemek__legalservices-dropdown_content {
    padding-top: 0.5rem;
}

.jemek__legalservices-item-wrapper-two {
  padding: 1rem!important;

}

.jemek__legalservices-links-header {
  padding-top: 3rem;
  padding-bottom: 1.25rem;
}

.jemek__legalservices-text {
  letter-spacing: 0.1rem;
}

.jemek__legalservices-links-header h2 {
  font-size: 0.8rem;
}

.jemek__legalservices-text {
  padding:0.35rem;
  font-size: 0.7rem;
}

.jemek__legalservices-icon {
  font-size: 1.05rem;
}

.jemek__legalservices-dropdown_content p {
  font-size: 0.5rem;
}

}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .jemek__legalservices-explore-container.container {
    max-width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
}
  
  .jemek__legalservices-links-header h2 {
    font-size: 1rem;
}

.jemek__legalservices-text {
  letter-spacing: 0.1rem;
}

.jemek__legalservices-item-wrapper-two {
  padding: 1rem!important;
}


.jemek__legalservices-links-header {
  padding-top: 3rem;
  padding-bottom: 1.25rem;
}

.jemek__legalservices-icon {
  font-size: 1.2rem;
}

  .jemek__legalservices-text {
    padding: 0.35rem;
    font-size: 0.8rem;
}

.jemek__legalservices-dropdown_content p {
  font-size: 0.7rem;
}

.jemek__legalservices-dropdown_content {
  padding-top: 1rem;
}

}

@media screen and (min-width:1320px) {

  .jemek__legalservices-dropdown_content p {
    font-size: var(--step-0)!important;
}

}
