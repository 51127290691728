.jemek__member-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 15%;
    padding-bottom: 10%;
    background-image: url(/home/ghost/Documents/codingprojs/react_app_jemek/frontend_react/src/assets/solicitor-background.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    }

    
    
    .jemek__member-header-text {
    color: white;
    }

    .jemek__member-content {
      max-width: 1320px;
      margin:0 auto;
    }
    
    .jemek__member-content-textbox {
    display: flex;
    flex-direction: column;
    }
    
    .jemek__member-content-textbox p {
      background-color: #f7f7f7;
      padding: 3.125rem;
      padding-left: 13%;
      padding-right: 13%;
      font-size: 1.1rem;
      width: 90%;
      line-height: 2.6rem;
      font-weight: 300;
      color: rgb(0, 0, 0);
      margin-top: -5rem;
      margin-left: auto;
      margin-right: auto;
    }
    
    .jemek__member-content__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
    }

    .jemek__member-content__container.container {
      max-width: 100%;
  }
    
    .jemek__member-content__header h2 {
    font-weight: 300;
    }
    
    .jemek__member-content__header h3 {
    font-weight: 300;
    }
    
    .jemek__member-content__header p {
    font-weight: 300;
    }
    
    .jemek__member-content__row-one {
    flex-direction: row!important;
    padding-top: 3rem;
    display: grid!important;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    /* place-items: center; */
    margin-top: auto;
    margin-right: auto;
    margin-left: auto;
    /* padding-bottom: 3rem; */
    justify-items: center;
    }
    
    .jemek__member-content__row-two {
    background-color: #fbfbfb;
    padding: 3.125rem;
    }
    
    .jemek__member-content__row-two p {
      /* margin-left: auto!important; */
      /* margin-right: auto!important; */
      width: 100%!important;
      font-size: 1.1rem;
      font-weight: 300;
      line-height: 3.7rem;
    }
  
  
    
    
    .jemek__member-content__row-one h3 {
      border-bottom: 0.3rem solid #5997e9;
      width: 100%;
      color: #192E47;
      font-weight: 300;
      padding-bottom: 2rem;
  
    }
    
    .jemek__member-content__column-one {
    margin-bottom: 0!important;
    }
    
    .jemek__member-content__column-one,
    .jemek__member-content__column-two,
    .jemek__member-content__column-three{
      width: 70%!important;
    }
  
    .jemek__member-content__column-one p,
    .jemek__member-content__column-two p,
    .jemek__member-content__column-three p {
    padding-top: 2rem!important;
    }
    
  
    .jemek__member-content__column-three p {
    padding-top: 2rem;
    }
    
    .jemek_ourteam-content-span {
    font-weight: 500;
    }
    
    .jemek__content-separator {
    padding-top: 9rem;
    }


    
    
    
    
    
  
  @media screen and (max-width: 480px) {
  
    .jemek__member-header {
      margin-left: auto;
      margin-right: auto;
      padding-top: 8.45rem;
      padding-bottom: 5.15rem;
      width: 100%;
    }
  
    .jemek__member-header-text h1 {
      color: rgb(255, 255, 255)!important;
      font-size: 1.25rem!important;
    }
  
    .jemek__member-content__row-one {
      padding-top: 2rem;
      padding-bottom: 1rem;
      flex-direction: column!important;
      justify-content: center!important;
      justify-items: center;
  }

  .jemek__member-content-textbox p {
    font-size: 0.5rem;
    width: 89%;
    line-height: 1.2rem;
    margin-top: -3.4rem;
    padding: 1.125rem;
  }
  
  .jemek__member-content__header {
    padding-top: 2rem;
  }
  
  .jemek__member-content__header h2 {
    font-weight: 300;
    font-size: 0.8rem;
}
  
  .jemek__member-content__header h3 {
    font-size: 0.7rem;
  }
  
  .jemek__member-content__header p {
    font-size: 0.5rem;
  }
  
  
  .jemek__member-content__row-one h3 {
  width: 70%;
  font-size: 0.8rem;
  border-bottom: 0.1rem solid #5997e9;
  padding-bottom: 1.4rem;
  }
  
  .jemek__member-content__column-one p, .jemek__member-content__column-two p, .jemek__member-content__column-three p {
    padding-top: 1.125rem!important;
    font-size: 0.5rem;
  }
  
  .jemek__member-content__row-two {
    background-color: #f7f7f7;
    padding: 1.5rem!important;
  }
  
  .jemek__member-content__row-two p {
    padding-top: 0.2rem;
    font-size: 0.5rem;
    line-height: 1.7rem;
    width: 100%;
  }
  
  
  
  }
  
  @media screen and (min-width: 481px) and (max-width: 768px) {
    .jemek__member-content__header {
      padding-top: 2rem;
  }

.jemek__member-header {
  padding-top: 30%;
  padding-bottom: 25%;
}

.jemek__member-content__header h2 {
  font-weight: 300;
  font-size: 1.5rem;
}

.jemek__member-content__header h3 {
  font-size: 1rem;
}

.jemek__member-content__header p {
  font-size: 0.8rem;
}

.jemek__member-content__row-one h3 {
  width: 70%;
  font-size: 1rem;
  border-bottom: 0.1rem solid #5997e9;
  padding-bottom: 1.4rem;
}

.jemek__member-content__column-one p, .jemek__member-content__column-two p, .jemek__member-content__column-three p {
  padding-top: 1.125rem!important;
  font-size: 0.8rem;
}

.jemek__member-content__row-two {
  background-color: #f7f7f7;
  padding: 2rem;
}

.jemek__member-content__row-two p {
  width: 100%!important;
  font-size: 0.8rem;
  font-weight: 300;
  line-height: 2.8rem;
}


  
  }
  
  @media screen and (min-width: 768px) and (max-width: 1026px) {
  
  }
  
  
  @media screen and (min-width: 1300px) {
    .jemek__member-content__column-one p,
    .jemek__member-content__column-two p,
    .jemek__member-content__column-three p {
      font-size: var(--step-0)!important
    }
  }