.jemek__templateone-header {
    width:100%;
    background-image: url(/home/ghost/Documents/codingprojs/react_app_jemek/frontend_react/src/assets/abstract-blue.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 15%;
    padding-bottom: 10%;
    color: white;
}




.jemek__templateone-content-one-textbox p {

    background-color: #fbfbfb;
    padding: 50px;
    padding-left: 13%;
    padding-right: 13%;
    font-size: 18px;
    width: 90%;
    line-height: 2rem;
    font-weight: 300;
    color: rgb(0, 0, 0);
    margin-top: -30px!important;
    margin-left: auto;
    margin-right: auto;
}


.jemek__templateone-content-two-main h3 {

    font-weight: 300!important;
    width: 90%!important;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3%;
    padding-bottom: 1px;

    color: #241d1d;
}

.jemek__templateone-content-two-main p {

    font-weight: 300;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    line-height: 38px;
}

.jemek__templateone-content-two-text_one-sub h5 {
    font-weight: 400;
    font-size: 20px;
    color: #041833;
    padding-bottom: 30px;
    padding-top: 30px;
}

.jemek__templateone-content-two-text_one-sub p {

  font-size: 22px!important;
  margin-left: auto;
  margin-right: auto;
  width: 55%;

  
}


.jemek__templateone-content-two-text-three_wrapper {
    background-color: #192E47;
    width: 100%;
margin-top:40px;
margin-bottom: 40px;
padding-top: 20px;
padding-bottom: 20px;
}

.jemek__templateone-content-two-text_three {
    padding: 50px;
    margin: 0 auto;
    text-align: center;
    color: white;
  }


.jemek__templateone-content-two-text_three h2 {
    padding-bottom: 40px;
    color: #5997e9;
  }
.jemek__templateone-content-two-text_three p {
    margin-left:auto!important;
    margin-right: auto!important;
    width:60%!important;
  }


  .jemek__templateone-content-text-important p {
    font-size: 22px!important;
    margin-left: auto;
    margin-right: auto;
    width: 65%;
  }

  .jemek__template-one__summ-paragraph p {
    font-size: var(--step-1)!important;
    margin-left: auto;
    margin-right: auto;
    width: 65%;
    padding-bottom: 1.2rem;
}



  @media screen and (max-width: 480px) {
    .jemek__templateone-header-container-text h1 {
      font-size:1rem;
    }

    .jemek__templateone-content-one-textbox p {
      padding: 3.508333%;
      padding-left: 8%;
      padding-right: 8%;
      font-size: var(--step-1)!important;
      width: 90%;
      line-height: 1.5rem;
      margin-top: -2rem;
    }

    .jemek__templateone-content-two-main h2 {
      font-size: 0.9rem!important;
    width: 90%!important;
    padding-top: 0.566667rem;
    padding-bottom: 0.0666666667rem;
    }

    .jemek__templateone-content-two-main h3 {
      font-weight: 400!important;
    }

    .jemek__templateone-content-two-main p {
      font-size: 0.9rem!important;
      width: 90%;
      line-height: 1.5rem;
    }

    .jemek__template-one__summ-paragraph p {
      font-size: 0.9rem!important;
      margin-left: auto;
      margin-right: auto;
      width: 90%;
      padding-bottom: 0.5rem;
      line-height: 1.5rem;
  }
  

    
  }
  
  @media screen and (min-width: 481px) and (max-width: 768px) {
    .jemek__templateone-content-one-textbox p {
      padding: 3.508333%;
      padding-left: 12%;
      padding-right: 12%;
  
      width: 100%;
   
      margin-top: -4rem!;


      font-size: var(--step--1)!important;
      line-height: 1.1rem;
  }

  .jemek__templateone-content-two-main h2 {
    font-size: 1.5rem!important;
    width: 90%!important;
    padding-top: 0.566667rem;
    padding-bottom: 0.0666666667rem;
}

.jemek__templateone-content-two-main p {
  font-size: 0.8rem!important;
  width: 80%;
  line-height: 2rem;
}
  }
  
@media screen and (min-width: 1300px) {
  .jemek__templateone-content-one-textbox p {

    font-size: var(--step-0)!important;

}
}