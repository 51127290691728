.jemek__ourpractice-header {
  width: 100%;
  background-image: url(/home/ghost/Documents/codingprojs/react_app_jemek/frontend_react/src/assets/abstract-blue.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 15%;
  padding-bottom: 10%;
  }
  
  .jemek__ourpractice-header-container-text {
  color: white;
  }
  
  .jemek__ourpractice-content-one-textbox {
  display: flex;
  flex-direction: column;
  }
  
  .jemek__ourpractice-content-one-textbox p {
  background-color: #fbfbfb;
  padding: 4.008333%;
  padding-left: 9%;
  padding-right: 9%;
  font-size: 1.1rem;
  width: 90%;
  line-height: 2rem;
  font-weight: 300;
  color: rgb(0, 0, 0);
  margin-top: -4rem!important;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5%!important;
  }
  
  .jemek__ourpractice-data-container {
  background-image: url(/home/ghost/Documents/codingprojs/react_app_jemek/frontend_react/src/assets/abstract-blue3.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-attachment: fixed;
  display: flex!important;
  flex: 50;
  justify-content: center;
  flex-direction: column;
  will-change: transform;
  margin: 0 auto;
  max-width: 1920px;
  }
  
  .jemek__ourpractice-components {
  display: flex;
  flex: 50;
  justify-content: center;
  }
  
  .jemek__ourpractice-components-cards {
  display: flex!important;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
  width: 100%!important;
  max-width: 1100px;
  }
  
  .jemek__ourpractice-cards-wrapper {
    margin-top: 4rem;

    display: flex !important;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: center;
    width: 100% !important;
    padding: 3.125rem 0.475rem 3.125rem 0.475rem;
    flex: 40% 1;
    position: relative;
}

  
  .jemek__ourpractice-component-one,
  .jemek__ourpractice-component-two,
  .jemek__ourpractice-component-three {
  background: none;
  display: flex;
  flex: 50;
  }
  
  .jemek__ourpractice-component-one button,
  .jemek__ourpractice-component-two button,
  .jemek__ourpractice-component-three button{
  border: none;
  }
  
  .jemek__ourpractice-components button {
    display: inline-block;
    background: transparent;
    line-height: 4rem;
    flex: 50 1;
    font-size: var(--step-1)!important;
    font-weight: 400;

    color: white;
    transition: 0.5s;

    padding: 6%;
  }
  
  .jemek__ourpractice-component-one button:hover,
  .jemek__ourpractice-component-two button:hover,
  .jemek__ourpractice-component-three button:hover {
  color: whitesmoke!important;
  background-color: rgb(37 37 64 / 39%);
  }
  
  .jemek__ourpractice-components button.active {
  background-color: transparent;
  color: white;
  }
  
  .jemek__ourpractice-components button.active:hover {
  background-color: rgb(37 37 64 / 39%);
  color: white;
  }
  
  .jemek__ourpractice-components button.inactive {
  background-color: rgba(128, 128, 128, 0.5);
  color: white;
  opacity: 70%;
  }
  
  .jemek__ourpractice-components button.inactive:hover {
  background-color: rgb(37 37 64 / 39%);
  color: white;
  opacity: 100%;
  }
  
  .jemek__ourpractice-components-cards {
  display: inline-block;
  margin: 0 auto;
  padding: 3%;


  }
  
  .jemek__ourpractice-cards-grid {
  display: grid!important;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3.2rem;
  gap: 1.5rem;
  -moz-column-gap: 3.2rem;
  column-gap: 1.5rem;
  row-gap: 2.5rem;
  padding-bottom: 6rem;
  }
  
  .jemek__ourpractice-first-card,
  .jemek__ourpractice-second-card,
  .jemek__ourpractice-third-card,
  .jemek__ourpractice-fourth-card,
  .jemek__ourpractice-fifth-card,
  .jemek__ourpractice-sixth-card,
  .jemek__ourpractice-seventh-card,
  .jemek__ourpractice-eighth-card,
  .jemek__ourpractice-nineth-card,
  .jemek__ourpractice-tenth-card {
    display: flex;
    flex-direction: column;
  background-color: rgb(255, 255, 255);
  transform: translate(0px, 0px);
  width: 100%;
  opacity: 1;
  }
  
  .jemek__card-image {
  align-self: stretch;
  }
  
  .jemek__card-link {
  /* display: flex; */
  flex-direction: column;
  width: 100%;
  text-decoration: none;
  }
  
  .jemek__card-img-wrapper {
  display: flex;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 17rem;
  }
  
  .jemek__card-img-wrapper img {
  vertical-align: top;
  align-self: flex-start;
  width: 100%;
  height: 100%;
  object-fit: cover;
  }
  
  .jemek__data-container {
    display: flex;
    flex-direction: column;
    padding: 17px 9px;
    height: 100%;
  }
  
  .jemek__card-data-title {
  line-height: 2rem;
  min-height: 3rem;
  color: #241d1d;
  font-weight: 400;
  transition: 0.5s;
  }

  .jemek__card-data-title:hover {


    color: #316098;

    }
  
  .jemek__card-data {
    padding: 5% 3%;

  }
  
  .jemek__cards-data-bottom {
    padding: 5% 3%;
    margin-top: auto;
  }

  .jemek__cards-data-redirect-link {
    color: #316098;
    transition: 0.5s;
  }

  .jemek__cards-data-redirect-link:hover {
    color: #7399c6;
  }

  .jemek__cards-data-redirect-link-wrapper span:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 5px;
    background-color: #7399c6;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
  }

  .jemek__cards-data-redirect-link-wrapper span:hover:after {
    transform: scaleX(1);
    width: 100%;
  }




/* start of animations css */



@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes cardAppear {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

 /*
.card-animation {
  opacity: 0;
  animation: fade-in 3s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.8s both;
}
*/ 


.jemek__ourpractice-immigration-contents.active,
.jemek__ourpractice-family-contents.active,
.jemek__ourpractice-childcare-contents.active {
  max-height: 0;
  transform: translateX(0);
}


.jemek__ourpractice-wrapper a {
  color: black!important;
}
.jemek__ourpractice-wrapper a:hover {
  color: blue!important;
}





@media screen and (max-width: 480px) {
  .jemek__ourpractice-header-container-text h1 {
    font-size: 1rem;
  }
  
  .jemek__ourpractice-cards-grid {
    display: grid!important;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
}


.jemek__ourpractice-content-one-textbox p {

  padding: 3.508333%;
  padding-left: 8%;
  padding-right: 8%;
  font-size: var(--step-1)!important;
  width: 90%;
  line-height: 1.5rem;
  margin-top: -1.5rem!important;

}

.jemek__ourpractice-components button {
  display: inline-block;
  background: transparent;
  line-height: 0.9rem;
  flex: 50 1;
  font-size: var(--step-0)!important;
  font-weight: 400;
  padding: 7%;
  color: white;
}

.jemek__ourpractice-components-cards {
  display: inline-block;
  padding: 5% 5% 5% 5%;
}

.jemek__card-data {
 /* padding: 1rem 0.5rem 1rem 0.5rem; */
  min-height: 0.5rem;
}

.jemek__ourpractice-cards-wrapper {
  margin-top: 1rem;
  padding: 0px;
  position: relative;
}

.jemek__card-img-wrapper img {
  vertical-align: top;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.jemek__card-img-wrapper {
  display: flex;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 10rem;
}

.jemek__card-data-title {
  line-height: 1.2rem;
  min-height: 1rem;
  font-size: 0.8rem;
}

.jemek__card-data-description p{ 
  min-height: 3rem;
  font-size: 0.5rem;
}

.jemek__cards-data-redirect-link-wrapper a  {
  font-size: 0.6rem;
}

.jemek__cards-data-bottom {

  line-height: 0rem;
  margin-top: auto;
}

}

@media screen and (min-width: 481px) and (max-width: 768px) {

  .jemek__card-data {
    padding: 5%;
}

  .jemek__ourpractice-cards-grid {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.5rem;
    row-gap: 1.5rem;
  }

  .jemek__card-data-description {
    min-height:6rem;
}

.jemek__ourpractice-content-one-textbox p {
  font-size: var(--step--1)!important;
  line-height: 1.1rem;
  margin-top: -2rem!important;
}

.jemek__ourpractice-cards-wrapper {
  padding: 1%;
}

.jemek__ourpractice-components button {
  line-height: 1.5rem;
}

.jemek__cards-data-bottom {
  padding: 0.5rem 5% 0.5rem;
}

.jemek__card-data h3 {
  min-height: 2rem;
  line-height: 1.5rem;
}

.jemek__cards-data-redirect-link-wrapper a  {
  font-size: 0.7rem;
}


}

@media screen and (min-width: 769px) and (max-width: 1026px) {

}

@media screen and (min-width:1320px) {

  .jemek__card-data-title {

    font-size: var(--step-2)!important;

}


.jemek__ourpractice-content-one-textbox p {
  font-size: var(--step-0)!important;
}



  .jemek__card-data-description p {

    font-size: var(--step-0)!important;
}

.jemek__aboutus-copy-right-muted p {
  font-size: var(--step--1)!important;
}
}


