.jemek__legalaid-sectiontwo {
    background-color: #192E47;
    padding-top: 7%;
    padding-bottom: 7%;
}

.jemek__legalaid-content-columnone,
.jemek__legalaid-content-columntwo {
    flex: 0 0 auto;
    padding-left: 0rem!important;
    width: 100%!important;
    padding-right: 0rem!important;
    display: flex;
    flex-direction: column;
    justify-content: center;
}



.jemek__legalaid-content-columntwo {
    padding-left: 1rem!important;
}

.jemek__legalaid-column-image img {
    width: 100%;
    background-size: cover;
    margin-right: auto;
    height: 31.25rem;
}

/*
.jemek__legalaid-column-image {
    max-width: 95% !important;
    height: auto;
    margin-top: -0.625rem;
    margin-bottom: -19.375rem;
    margin-left: -1.25rem !important;
    height: 39.375rem !important;
}

*/

.jemek__legal-aid-content__item-header {
    padding-bottom: 1rem;
}

.jemek__legal-aid-content__item-header h3 {
    font-size: 1.5rem;
    color: white;
}

.jemek__legal-aid-content__item-highlight {
    color: #5997e9;

}

.jemek__legal-aid-content__items {
    position: relative!important;
    max-width: 100%;
    display: grid!important;

    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    grid-template-rows: minmax(20rem, auto);
    grid-auto-flow: dense;
    grid-gap: 2.5rem
}

.jemek__legal-aid-content__item-text_h3 h3 {
    margin-bottom: auto;
}



.jemek__legal-aid-content__item-button {
    margin-top: auto;
}

.jemek__legal-aid-content__item-button button:hover {
    color: black;
    background-color: white;
    border-radius: 0rem;
    padding: 0.4rem;
    border: 0.1rem solid;
}



.jemek__legal-aid-content__item-text_p p {
    margin-top: auto;
    color: white;
}

.jemek__legal-aid-content__items .jemek__legal-aid-content__item-wrapper:hover {
    background: #5997e97d;
    transform: scale(1.05);
    box-shadow: 1px 23.1px 23px rgba(2, 0, 0, 0.2);
}

.jemek__legal-aid-content__items .jemek__legal-aid-content__item-wrapper {
    display: flex;
    background: #e3e5e959;
    width: 100%;
    padding: 11%;
    font-size: 0.9rem;
    color: black;
    transition: 0.5s;

    transition: transform 0.5s;
}



.jemek__legal-aid-content__item-image img {
    width: 100%;
    height: 12rem;
}

.jemek__legal-aid-content__item-text {
    padding-top: 1.5rem;
}

.jemek__legal-aid-content__item-button button {
    color: white;
    background-color: black;
    border-radius: 0rem;
    padding: 0.4rem;
    border: 0.1rem solid black;
    transition: 0.5s;
}



.jemek__legalaid-column-text {
    padding: 1.25rem;
    background-color: #5e626557;
    color: white;
    font-weight: 300;
    font-size: 1.5625rem;
    width: 100%;
}

.jemek__legalaid-column-btn {
    display: flex;
    justify-content: flex-end;
    padding-top: 0.45rem;
}

.jemek__legalaid-column-btn button {
    padding: 0.6rem;
    font-size: 1.2rem;
    border-radius: 1px;
    color: white;
    background-color: black;
}

.jemek__legalaid-content-header h3 {
    font-size: 2.3125rem;
    font-weight: 300!important;
    width: 80%!important;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1%;
    padding-bottom: 0.0625rem;
    color: #241d1d;
}

.jemek__legalaid-content-paragraph p {
    font-size: 1.1875rem;
    font-weight: 300;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    line-height: 2.375rem;
}

.jemek__legalaid-sectionthree-contents {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.jemek__legalaid-sectionone-contents {
    padding-bottom: 3rem;
    padding-top: 1%;
}

.jemek__legal-aid-content__item-text {
    padding-bottom: 0rem;
    padding-top: 6%;
}

.jemek__legal-aid-content__item-text h4 {

    color: white;
}

.jemek__legal-aid-content__item-text_h3 .span-text-highlight {
    color: white;
    transition: 0.5s;
}

.jemek__legal-aid-content__item-wrapper:hover .jemek__legal-aid-content__item-text span {
    color: #5997e9;

} 

.jemek__legal-aid-content__item-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.jemek__legal-aid-content__item-one, 
.jemek__legal-aid-content__item-two,
.jemek__legal-aid-content__item-three {
    display: flex;
    flex-direction: column;
    transform: translate(0px, 0px);
    width: 100%;
    opacity: 1;
}


@media screen and (max-width: 480px) {

    .jemek__legalaid-sectionone-contents {
        padding-bottom: 3rem;
        padding-top: 1rem;
    }

    .jemek__legalaid-content-header h2 {
        font-size: 0.9rem!important;
        width: 90%!important;
        padding-top: 0.566667rem;
        padding-bottom: 0.0666666667rem;
    }

    .jemek__legalaid-content-header h3 {

        font-weight: 400!important;

    }

    .jemek__legalaid-content-paragraph p {

        width: 90%;
        line-height: 1.5rem;
        font-size: 0.9rem!important;
    }


.jemek__legalaid-content-row.row {
    width: 100%;
    height: auto;
    margin-left: 0rem!important;
}

    .jemek__legalaid-sectiontwo {
        background-color: #192E47;

    }

.jemek__legalaid-column-text {
    padding: 0.25rem;
    background-color: #5e626557;
    color: white;
    font-weight: 300;
    font-size: 0.4625rem;
    width: 100%;
}

.jemek__legalaid-column-text p {
    margin-bottom: 0rem;
}

.jemek__legal-aid-content__items {
    display: flex!important;
    flex-direction: row;
    height: 100%;
    place-items: unset;
    grid-gap: 1rem;
    padding:0rem;
}

.jemek__legal-aid-content__items .jemek__legal-aid-content__item-wrapper {
width: 50%;

padding: 1rem;
}

.jemek__legal-aid-content__item-image img {
    width: 100%;
    height: 6rem;
}

.jemek__legalaid-content-container {
    padding-left: 0.5rem!important;
    padding-right: 0.5rem!important;
}

.jemek__legal-aid-content__item-text h3 {
    padding-bottom: 0rem;
    color: white;
    font-size: 1rem;
    min-height: 1rem;
}

.jemek__legal-aid-content__item-text_p p {
    margin-top: auto;
    color: white;
    font-size: 0.6rem;
}

.jemek__legal-aid-content__item-button button {
    padding: 0.4rem;
    font-size: 0.4rem;
}

.jemek__legal-aid-content__item-text {
    min-height: 10rem;
}

/*
.jemek__legalaid-column-image {
    max-width: 100% !important;
    height: auto;
    margin-top: -0.625rem;
    height: 9.375rem !important;
}
*/

.jemek__legalaid-column-image img {
    width: 100%;
    background-size: cover;
    height: 8.5rem;
}

.jemek__legalaid-content-columntwo {
    padding-left: 0.5rem!important;
}

.jemek__legalaid-column-btn {
    display: flex;
    justify-content: flex-end;
    padding-top: 0.45rem;
}

.jemek__legalaid-column-btn button {
    padding: 0.3rem;
    font-size: 0.3rem;
    border-radius: 1px;

}

}
  
  @media screen and (min-width: 481px) and (max-width: 768px) {

    .jemek__legalaid-sectionone {
        padding-top: 0.5rem!important;
    }

    .jemek__legalaid-sectionone-contents {
        padding-bottom: 2rem;
        padding-top: 1rem;
    }

    .jemek__legalaid-content-header h2 {
        font-size: 1rem!important;
        padding-top: 1rem;
    }

    .jemek__legalaid-sectiontwo {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .jemek__legalaid-content-paragraph p {
        font-size: 0.8rem!important;
        width: 100%;
        line-height: 1.5rem;
    }

    .jemek__legal-aid-content__items {
        display: flex!important;
        flex-direction: row;
        height: 100%;
        place-items: unset;
        grid-gap: 1rem;
        padding:0rem;
    }

    .jemek__legal-aid-content__item-image img {
        width: 100%;
        height: 8rem;
    }

    .jemek__legal-aid-content__item-text h3 {
        padding-bottom: 1.5rem;
        color: white;
        min-height: 4.1rem;
    }

    .jemek__legal-aid-content__items .jemek__legal-aid-content__item-wrapper {
        padding: 5%;
    }

    .jemek__legal-aid-content__item-text_p p {
        margin-top: auto;
        color: white;
        font-size: 0.7rem;
    }

    .jemek__legalaid-content-container {
        padding-left: 0.5rem!important;
        padding-right: 0.5rem!important;
        max-width: 620px!important;
    }

  }
  
  @media screen and (min-width: 769px) and (max-width: 1026px) {
    

.jemek__masonrycard-items {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
    grid-template-rows: minmax(auto, auto);
}
  }

@media screen and (min-width: 1320px) {
    .jemek__legal-aid-content__item-text_p p {
        font-size: var(--step-0)!important;
    }
}