
:root {
  --font-family: 'Roboto', sans-serif;
  --gradient-text: linear-gradient(89.97deg, #000000 1.84%, #e9aa88 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #000000 -13.86%, #F49867 99.55%);
  
  --color-bg: #ffffff;
  --color-footer : #ffffff;
  --color-blog: #000000;
  --color-text: #000000;
  --color-subtext: #000000;
}


