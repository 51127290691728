.jemek__eea-header {
    width: 100%;
    background-image: url(/home/ghost/Documents/codingprojs/react_app_jemek/frontend_react/src/assets/abstract-blue.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 15%;
    padding-bottom: 10%;
    color: white;
}

.jemek__eea-content-one-textbox p {
    background-color: #fbfbfb;
    padding: 4.008333%;
    padding-left: 9%;
    padding-right: 9%;
    font-size: 1.1rem;
    width: 90%;
    line-height: 2rem;
    font-weight: 300;
    color: rgb(0, 0, 0);
    margin-top: -4rem!important;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%!important;
}

.jemek__eea-content-two-main h3 {

    font-weight: 300!important;
    width: 60%!important;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1%;
    padding-bottom: 0.0666666667rem;

}

.jemek__eea-content-two-main p {

    font-weight: 300;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    line-height: 2.5rem;
}

.jemek__eea-content-two-text_one-sub h5 {
    font-weight: 400;

    color: #041833;
    padding-bottom: 2%;
    padding-top: 2%;
}

.jemek__eea-content-two-container-one, .jemek__eea-content-two-container-two
 {
    max-width: 100%!important;
}

.jemek__eea-content-two-text_one-sub p {

    margin-left: auto;
    margin-right: auto;
    width: 55%;
}

.jemek__eea-content-two-text-three_wrapper {
    background-color: #192E47;
    width: 100%;
    margin-top: 2.6666666667rem;
    margin-bottom: 2.6666666667rem;
    padding-top: 0.5333333333rem;
    padding-bottom: 0.5333333333rem;
}

.jemek__eea-content-two-text_three {
    padding: 4%;
    margin: 0 auto;
    text-align: center;
    color: white;
}

.jemek__eea-content-two-text_three h2 {
    margin-left: auto;
    margin-right: auto;
    color: #5997e9;
    font-weight: 300;
}

.jemek__eea-content-two-text_three p {
    margin-left: auto!important;
    margin-right: auto!important;
    width: 90%!important;
}

.jemek__eea-content-text-important p {

    margin-left: auto;
    margin-right: auto;
    width: 65%;
    padding-bottom: 1.2rem;
}

.jemek__eea-component-cards {
    padding: 3.125rem 21.875rem 3.125rem 21.875rem;
    display: flex!important;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: center;
    width: 100%!important;
}

.jemek__eea-cards-grid {
    display: grid!important;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
    gap: 2rem;
    column-gap: 2rem;
    row-gap: 2rem;
    padding-bottom: 3.75rem;
}

.jemek__eea-card-data {
    padding: 2rem 2rem 2rem 0px;
}


@media screen and (max-width: 480px) {

    .jemek__eea-header-container-text h1 {
        font-size: 1rem;
    }

    .jemek__eea-content-two-main h3 {
        font-weight: 400!important;
    }

    .jemek__eea-content-one-textbox p {
    padding: 3.508333%;
    padding-left: 8%;
    padding-right: 8%;
    font-size: var(--step-1)!important;
    width: 90%;
    line-height: 1.3rem;
    margin-top: -1.5rem!important;

    }
    
    .jemek__eea-content-text-important p {

        margin-left: auto;
        margin-right: auto;
        width: 94.6%;
        padding-bottom: 1.2rem;
    }

    .jemek__eea-content-two-main h2 {
        font-weight: 400!important;

        width: 90%!important;
        padding-top: 1.366667rem;
        padding-bottom: 0.0666666667rem;
    }

    .jemek__eea-content-two-main p {

        width: 90%;
        line-height: 1.5rem;
    }

    .jemek__eea-content-two-text_one-sub h5 {
        font-weight: 400;
        font-size: 0.55rem;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        line-height: 1.3rem;
        padding-bottom: 0.675rem;
        padding-top: 0.675rem;
    }

    .jemek__eea-content-two-text_three p {
        margin-left: auto!important;
        margin-right: auto!important;
        width: 90%!important;
    }
  }

@media screen and (min-width: 481px) and (max-width:768px) {
    .jemek__legalservices-item-wrapper {
        padding: 1rem!important;
    }

    .jemek__eea-content-one-textbox p {
        font-size: var(--step--1)!important;
        line-height: 1.1rem;
        margin-top: -2rem!important;
    }
}
  
@media screen and (min-width: 1300px) {
    .jemek__eea-content-one-textbox p {
        font-size: var(--step-0)!important;
    }
}
  