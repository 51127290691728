.jemek__footer {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: 3rem!important;
  padding: 1rem;
}

.jemek__footer-container {
  display: flex;
  flex: 1;
  max-width: 100%!important;
}

.jemek__footer-grid-items {
  display: flex;
  position: relative;
  width: 100%;
}

.jemek__footer-grid_logo {
  margin: 0;
  padding: 1rem;
  width: 50%;
}

.jemek__footer-grid_logo img {
  width: auto;
  height: 5.45rem;
  margin-bottom: 1rem;

}

.jemek__list {
  width: 100%;
}

.jemek__footer-grid {
  flex: 1;
  margin: 0;
  padding: 1rem;
  width: 25%;
}

.jemek__footer-grid img {
  width: 50%;
}



.jemek__footer-grid h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: -0.04em;
  color: rgb(0, 0, 0);
}


.jemek__footer-item {
  display: flex;
  justify-content: flex-end;
}

.jemek__footer-grid p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.875rem;
  color: var(--color-text);
  margin-bottom: 2rem;
  overflow: hidden;
  width: 4.84375rem;
}

.jemek__footer-grid li {
  font-family: var(--font-family);
  font-weight: 400;
  letter-spacing: 0.009rem;
  line-height: 1.5rem;
  color: var(--color-text);
  margin-bottom: 0.2rem;
  overflow: hidden;
}

.jemek__list a {
  color: black;
}

.jemek__list a:hover {
  color: #a4a4a4ed;
}

.footer-grid__list-items .ul .li {
  font-size: 1rem;
}

/* Media queries for responsiveness */
@media screen and (max-width: 320px) {
  .jemek__footer-container a, li {
    font-size: 0.45rem!important;
  }
}

@media screen and (max-width: 480px) {
  .jemek__footer {
    padding-top: 2rem!important;
  }

  .jemek__footer-grid_logo {
    margin: 0;
    padding: 0.2rem;
    width: 50%;
  }
  
  .jemek__footer-grid_logo img {
    width: 6rem;
    height: auto;
    padding-bottom: 1rem;
    margin-left: 0rem;
  }

  .jemek__footer-container h1 {
    font-size: 0.8rem!important;
  }

  .jemek__footer-container a,
  li {

    color: unset!important;
    line-height: 1.3rem!important;
    font-size: var(--step-0)!important
  }

  .jemek__footer-grid {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    margin: 0!important;
    padding: 0!important;
    width: 70%;
  }



  .jemek__footer-container {
    display: flex!important;
    flex-direction: column!important;
  }

  .jemek__footer-grid-items {
    flex-direction: column;
  }

  .yoshkiimg,
  .yoshkidiv {
    max-width: 100% !important;
    background-size: contain !important;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {

  .jemek__footer-grid-items {
    flex-direction: column;
  }


  .jemek__footer-grid {
    flex-basis: 100%;
    padding: 0.5rem;
    min-width: 12rem;
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .jemek__footer-grid_logo {
    margin: 0;
    padding: 0.5rem;
    width: 100%;
  }
  .jemek__footer-grid_logo img {
    width: auto;
    height: 4rem;
    margin-bottom: 1.5rem;
    padding: 0rem;

  }

  .jemek__footer-grid li {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .jemek__footer {
    padding: 0rem 1rem;
}

  .jemek__footer-grid {
    flex: 1 1;
    margin: 0;
    padding: 1rem;
    width: 55%;
    padding-bottom: 0rem;
  }

  .jemek__footer-grid-items {
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: column;
}
}

@media screen and (min-width: 769px) {

.jemek__footer-grid h4 {
  font-size: var(--step-1)!important;
}
}