

@media screen and (min-width:481px) and (max-width: 768px) {


    .jemek__templateone-header-container-text h1 {
        font-size: 1.5rem;
    }

}

