
.jemek__masonrycard-items {
    padding-left: 0rem!important;
    position: relative!important;
    max-width:100%!important;
    display: grid!important;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    grid-template-rows: minmax(20rem, auto);

    grid-auto-flow: dense;
    grid-gap: 1.5rem;
}

.jemek__masonry-card__header h2 {
    padding-bottom: 1rem;
}

.jemek__masonry-card__header h3 {
    padding-bottom: 1.5%;
}

.jemek__masonry-card__span-highlight {
    color: #5997e9;
}

.jemek__masonrycard-items-container {
    padding-left: 0rem!important;
    padding-right: 0rem!important;
}

.jemek__masonry-card__text-container {
    display: flex;
    flex-direction: column;
    padding: 2% 0 0 0;
    height: auto;
    color: #241d1d;
}


.jemek__masonrycard-one,
.jemek__masonrycard-two,
.jemek__masonrycard-three,
.jemek__masonrycard-four {
    display: flex;
    flex-direction: column;
    transform: translate(0px, 0px);
}

.jemek__masonrycard-item-wrapper:hover .jemek__masonry-card__text-container span
 {
    color: #5997e9;
}

.jemek__masonry-learnmore p {
    margin-top: auto!important;
}

.jemek__masonrycard-items .jemek__masonrycard-item-wrapper {
    background: #e3e5e959;
    padding:2em;
    display:grid;
    justify-items: center;
    font-size: 0.9rem;
    color: black;
    transition: transform 0.3s ease-in-out;
}

.jemek__masonrycard-items .jemek__masonrycard-item-wrapper:hover {
    transform: scale(1.05);
    box-shadow: 1px 23.1px 23px rgba(2, 0, 0, 0.2);


}

.jemek__masonrycard-item-wrapper:hover .jemek__masonry-card__text-container h4,
.jemek__masonrycard-item-wrapper:hover .jemek__masonry-card__text-container p {
    color: #fffafa;
    transition: 0.5s;
}


.jemek__masonrycard-items .jemek__masonrycard-item-wrapper:hover {
    background: #5997e97d;
}

.jemek__masonrycard-items .jemek__masonrycard-item-wrapper img{
    position:relative;
    width: 100%;
    margin-bottom: 1.3em;
}


.jemek__masonrycard-items .jemek__masonrycard-item-wrapper:nth-child(1)
{
    grid-column: span 1;
    grid-row: span 1;
}

.jemek__masonrycard-items .jemek__masonrycard-item-wrapper:nth-child(2)
{
    grid-column: span 1;
    grid-row: span 1;
}

.jemek__masonrycard-items .jemek__masonrycard-item-wrapper:nth-child(3)
{
    grid-column: span 1;
    grid-row: span 1;
}


.jemek__masonrycard-items .jemek__masonrycard-item-wrapper:nth-child(4)
{
    grid-column: span 1;
    grid-row: span 1;
}

@media screen and (max-width: 480px) {

    .jemek__masonry-card__header h2 {
        font-size: 1rem;
    }

.jemek__masonrycard-text p {
    font-size: 0.7rem!important;
    min-height: 1.2rem;
}
.jemek__masonrycard-primary {
    width: 100%;
}
.jemek__masonrycard-secondary-img {
    width: 100%;
}
.jemek__masonrycard-img img {
    width: 100%;
}

.jemek__masonrycard-text h3 {
    font-size: 0.8rem!important;
}


.jemek__masonrycard-items-container {
    padding-left: 0rem;
    padding-right: 0rem;
}
.jemek__masonrycard-items .jemek__masonrycard-item-wrapper {
    padding: 0.9em;
    place-items: flex-start; 
    justify-items: none;
}

.jemek__masonrycard-items {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: minmax(10rem, auto);

  }

.jemek__masonry-learnmore p {
    font-size: 0.5rem;
}
}
  
  @media screen and (min-width: 481px) and (max-width: 768px) {


    .jemek__masonrycard-items {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: minmax(10rem, auto);
    }

    .jemek__masonrycard-items-container {
        max-width: 100%!important;
    }

    .jemek__masonrycard-items{
        padding:0rem!important;
    }

  }
  
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    
    .jemek__masonrycard-items .jemek__masonrycard-item-wrapper:nth-child(1)
{
    grid-column: span 2;
    grid-row: span 2;
}

.jemek__masonrycard-items .jemek__masonrycard-item-wrapper:nth-child(2)
{
    grid-column: span 1;
    grid-row: span 2;
}

.jemek__masonrycard-items .jemek__masonrycard-item-wrapper:nth-child(3)
{
    grid-column: span 1;
    grid-row: span 2;
}


.jemek__masonrycard-items .jemek__masonrycard-item-wrapper:nth-child(4)
{
    grid-column: span 1;
    grid-row: span 2;
}

.jemek__masonrycard-items {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
    grid-template-rows: minmax(auto, auto);
    margin: 0rem;
}

.jemek__feepaying-bodytwo-container {
    max-width: 100%!important;
}

.jemek__masonrycard-items-container {
    max-width: 100%!important;
}
  }

  @media screen and (min-width: 1320px) {
    .jemek__masonry-card__text-description p {
        font-size: var(--step-0)!important;
    }

    .jemek__masonry-learnmore p {
        font-size: var(--step-0)!important;
    }
    
}