/* scroll bar start */

.jemek__exploremore {
    background-color: #101e2f;
    max-width: 1440px;
    margin: 0 auto;
}

.jemek__exploremore-media-scroller::-webkit-scrollbar {
    height: 6px;
  }
  
  .jemek__exploremore-media-scroller::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }
  
  .jemek__exploremore-media-scroller::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }
  
  .jemek__exploremore-media-scroller-outer {
    position: relative;
    overflow: hidden;

  }

  .jemek__exploremore-media-element {
    transition: 0.5s;
  }

  .jemek__exploremore-header h2 {
    font-weight: 300;
    color: whitesmoke;
    padding-top: 1rem;
    padding-bottom: 1rem;
  
  }
  
  .jemek__exploremore-media-element p {
    color: whitesmoke;
  }

  .jemek__exploremore-media-element:hover {
    transform: scale(1.05);
    box-shadow: 1px 23.1px 23px rgba(2, 0, 0, 0.2);
      
  }
  
  /* scroll bar width control webkit End */
  
  .jemek__exploremore-icon-container-left {
    display: flex;
    position: absolute;
    top: 47%;
    transform: translateY(-47%);
    left: 0.05rem;
    font-size: 1.4rem;
    line-height: 2rem;
    border-radius: 31px;
    color: whitesmoke;
    padding: 0.8rem;
    background-color: #adb5bda9;
    cursor: pointer;
    transition: 0.5s;
  
  }
  
  
  
  
  
  .jemek__exploremore-icon-container-right {
    display: flex;
    position: absolute;
    top: 47%;
    transform: translateY(-47%);
    right: 0.045rem;
    font-size: 1.5rem;
    line-height: 2rem;
    color: whitesmoke;
    border-radius: 31px;;
    padding: 0.8rem;
    background-color: #adb5bda9;
    cursor: pointer;
    transition: 0.5s;
    
  
  }
  
  .jemek__exploremore-media-scroller-outer > .jemek__exploremore-icon-container-left {
    visibility: hidden;
  }
  .jemek__exploremore-media-scroller-outer:hover > .jemek__exploremore-icon-container-left {
    visibility: visible;
    transition: 0.5s;
  }
  
  .jemek__exploremore-media-scroller-outer > .jemek__exploremore-icon-container-right {
    visibility: hidden;
  }
  
  .jemek__exploremore-media-scroller-outer:hover > .jemek__exploremore-icon-container-right {
    visibility: visible;
    transition: 0.5s;
  }
  
  @media (max-width: 768px) {
    .jemek__exploremore-icon-container-left,
    .jemek__exploremore-icon-container-right {
      font-size: 1.2rem;
    }
  }
  
  @media (max-width: 576px) {
    .jemek__exploremore-icon-container-left,
    .jemek__exploremore-icon-container-right {
      font-size: 1rem;
    }
  }
  
  .jemek__exploremore-media-scroller {
    --_spacer:1.5rem;
    display: grid;
    gap:var(--_spacer);
    grid-auto-flow: column;
    grid-auto-columns: 25%;
    scrollbar-width: thin;
    scroll-behavior: smooth;
    overflow-y: hidden;
    scroll-behavior: smooth;
  
    overflow-x: auto;
    overscroll-behavior-inline: contain; /* prevents overscrolling behaviour */
    padding: 0 var(--_spacer) var(--_spacer)
  }
  
  .jemek__exploremore-media-element {
    padding: 7%;
    background: 10rem;
    background-color: #e7e4e41e;
    cursor: pointer;
    border-radius: 0.2rem;
  }
  
  .jemek__exploremore-media-element > a > img {
    inline-size:100%;
    aspect-ratio: 16 / 15;
    object-fit: cover;

  }
  
  .snaps-inline {
    scroll-snap-type: inline mandatory;
  }
  
  .snaps-inline > * {
    scroll-snap-align: start;
  }
  
  p,h2,h3,h1 {
    font-family: var(--font-family)!important;
  }
  
  @media screen and (max-width: 480px) {
    .jemek__exploremore-media-scroller {
      grid-auto-columns: 50%;
      --_spacer: 0.9rem; 
  }
  
  .jemek__exploremore-header h2 {
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    font-size: 1rem;
  }
  
  .jemek__exploremore-media-element p {
  font-size: 0.6rem;
  padding-top: 0.8rem;
  }
  
  .jemek__content-separator {
    padding-top: 5.8rem;
  }
  
  .jemek__exploremore-icon-container-left {
  
    top: 39%;
    transform: translateY(-49%);
    left: -0.15rem;
    font-size: 1.5rem;
    padding: 0.05rem;
  }
  
  .jemek__exploremore-icon-container-right {
  
      top: 39%;
      transform: translateY(-49%);
  
      padding: 0.05rem;
  
  }
  
  .jemek__exploremore-icon-container-left svg,
  .jemek__exploremore-icon-container-right svg, path {  
    font-size: 0.5rem!important;
  
  }


  
  }

  @media screen and (min-width: 481px) and (max-width: 768px) {
    .jemek__exploremore-header h2 {
      padding-top: 2rem;
      padding-bottom: 2rem;
      font-size: 1rem;
    }
  }

  @media screen and (min-width: 1024px) {
    .jemek__exploremore-media-element p {
      font-size: var(--step--1)!important;
    }
  
  }