.jemek__navbar {
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  animation: fadeIn 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  position: sticky;
  width: 100%;
  top: 0;
  padding: 0.5rem;
  z-index: 102;
  justify-content: center;

  

  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.jemek__navbar.scrolled {
  transition: all 1s ease-in-out;
  animation: fadeIn 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.jemek__navbar__bottom-wrapper {
  position: relative;
  top: 0;
  transition: transform 1s ease-in-out;
  animation: fadeIn 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}




.jemek__navbar-container.scale-up-center button {
  padding: 0rem !important;
  font-size: var(--step-1)!important;

}

.jemek__navbar-breadcrumbs {
  margin-left: 3%;
  transition: transform 1s ease-in-out;
  animation: fadeIn 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.jemek__navbar-breadcrumbs .crumbs {
  display: flex;
  align-items: center;
}

.jemek__navbar-breadcrumbs .breadcrumbs span,
.jemek__navbar-breadcrumbs .breadcrumbs a,
.jemek__navbar-breadcrumbs .breadcrumbs bold {
  color: #241d1d;
  text-decoration: none;
  padding-right: 0.2rem;
  transition: 0.5s;
}

.jemek__navbar-breadcrumbs .breadcrumbs span {
  color: #747474!important;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  font-weight: 400;
}

.jemek__navbar-breadcrumbs .breadcrumbs a:hover {
  text-decoration: none;
  color: grey;
}


.jemek__navbar-breadcrumbs .breadcrumbs {
  margin-right: 0rem; 
}

.jemek__navbar-breadcrumbs-hidden .crumbs span {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  color: #747474!important;
}

.jemek__navbar-sign {
  margin-left: auto;
  transition: transform 1s ease-in-out;
  animation: fadeIn 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.jemek__navbar-links {
  display: flex;
  align-items: center;
  max-width: 1920px;
  margin: 0 auto;
  

  width: 100%;
  top: 0;
  padding: 0.5rem;
  z-index: 102;
  background-color: white;
}

.jemek__navbar-extracontent {
  display: flex;
  align-items: center;
  padding-left: 2.125rem;
}

.jemek__navbar-breadcrumbs-hidden .crumbs {
  display: flex;
  flex-direction: row;
  gap: 0.3125rem;
  margin-top: 1rem;
}

.jemek__navbar-breadcrumbs-hidden .breadcrumbs {
  display: flex;
  align-items: center;
  gap: 0.3125rem;
}

.jemek__navbar-breadcrumbs-hidden .breadcrumbs a:hover {
  text-decoration: none;
  color: grey;
}

.jemek__navbar-breadcrumbs-hidden .breadcrumbs a {
  color: #000;
  text-decoration: none;
  transition: 0.5s;
}

.jemek__navbar-menu_overlay {
  position: fixed;
  top: 15%; 
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

/* scroll bar start */
.jemek__navbar-header.lv-2 .jemek__navbar-header__inner::-webkit-scrollbar {
  height: 0.25rem; 
  width: 0.25rem;
  visibility: auto;
}

.jemek__navbar-header.lv-2::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0.0625rem; 
}

.jemek__navbar-header.lv-2::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0.125rem; 
}

/* scroll bar width control webkit End */

.jemek__navbar-menu_overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 101;
}

.jemek__navbar-links_logo img {
  height: 3.75rem; 
  width: auto;
}

.jemek__navbar-menu {
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 101;
}

.jemek__navbar-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  height: 32.75rem;
  top: calc(100% - 0.125rem);
  left: 0;
  width: 100%;
  background-color: #ffffff;
  cursor: auto;
  overflow-y: hidden;
  overflow-x: hidden;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  opacity: 0;
  padding: 2rem;
  z-index: 102;
  animation: scale-in-ver-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.jemek__navbar-header {
  position: absolute;
  top: 0;
  border-left: 1px solid rgb(244, 244, 244);
}

.jemek__navbar-header.lv-0 {
  padding: 27px 24px 57px 20px;
  width: 30%;
  height: 100%;
  border-left: none;
  transition: 0.5s;
}

.jemek__navbar-header.lv-1 {
  padding: 27px 20px 57px 18px;
  height: 100%;
  transition: 0.5s;
}

.jemek__navbar-header.lv-2 {
  padding: 57px 100px 57px 88px;
  height: 100%;
}

.jemek__navbar-header.lv-2 .jemek__navbar-header__inner {
  padding-right: 30%;
}

.jemek__navbar-header__inner {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 4px;
}

.jemek__navbar-header.lv-1 > .jemek__navbar-header__inner {
  padding-right: 20px;
}

.jemek__navbar-header.lv-2 {
  padding: 27px 0 37px 18px;
  width: 127%;
  left: calc(100% * 2);
}

.jemek__navbar-header.lv-1,
.jemek__navbar-header.lv-2 {
  width: 100%;
  left: 100%;
}

.jemek__navbar-item[class*="lv"] {
  padding: 14px 0;
  border-bottom: 1px solid rgb(244, 244, 244);
}

.jemek__navbar-item__link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: 0.5s;
}

.jemek__navbar-item__link,
.jemek__navbar-iconright {
  padding: 0;
  margin: 0;

  font-size: var(--step-1);
}

.navbar-font {
  font-size: var(--step-1)!important;
  font-weight: 300;
}

.jemek__navbar-item__text {
  display: inline-flex;
}

.jemek__navbar-item__link i {
  display: inline-block;
  font-size: 0.875rem;
  color: inherit;

  right: 7.5rem;
}

.jemek__navbar-item__external {
  margin-top: 40px;
  border: 0!important;
  padding: 4px 0;
}

.jemek__navbar-item__external .external {
  border: 0;
  padding: 4px 0;
  font-size: 0.8rem;
}

.jemek__navbar-item img {
  width: 45px;
  padding-right: 1rem;

}

.jemek__navbar-item .external {
  justify-content: flex-start!important;
}

.jemek__navbar-item__link .external a {
  padding-left: 1rem;
}

@keyframes scale-in-ver-top {
  0% {
    transform: scaleY(0);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}



.jemek__navbar-hover_one > a:hover {
  color: grey;
}

@keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.jemek__navbar-submenu_teaser {
  display: inline-flex;
  position: absolute;
  top: 0;
  overflow-x: scroll;
  left: 0;
  flex-direction: column;
  padding: 0.625rem 1.25rem 0.625rem 2.5rem;
  background-color: rgb(244, 244, 244);
  justify-content: center;
  width: 100%;
  overflow-x: hidden;
  height: 100%;
  animation: scale-in-hor-left 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.jemek__navbar-submenu_teaser-main {
  display: inline-flex;
  position: absolute;
  top: 0;
  left: 30%;
  flex-direction: column;
  padding: 0.625rem 1.25rem 0.625rem 2.5rem;
  background-color: rgb(244, 244, 244);
  justify-content: center;
  width: 100%;
  height: 100%;
  animation: scale-in-hor-left 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.jemek__navbar-submenu_teaser h1 {
  position: fixed;
  left: 10%;
  top: 28%;
  display: flex;
  font-weight: 400;
  font-size: var(--step-3)!important;
}

.jemek__navbar-submenu_teaser p {
  position: fixed;
  left: 10%;
  top: 43%;
  width: 85%;
  display: flex;
  font-weight: 300;
}

.jemek__navbar-submenu_teaser h1,
.jemek__navbar-submenu_teaser p {
  letter-spacing: 0.2px;
  line-height: 1.875rem; /* 30px */
  font-family: var(--font-family);
  animation: text-focus-in 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(0.75rem);
    filter: blur(0.75rem); 
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(0.75rem); 
    filter: blur(0.75rem); 
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

.jemek__nav-bar-sign {
  transition: opacity 0.5s;
}

.jemek__navbar-sign button {
  background-color: #100000;
  color: white;
  border: 1px solid;
  border-radius: 0.05rem;
  padding: 0.5rem 0.8rem 0.5rem 0.8rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.5s ease-in-out;
}

.jemek__navbar-sign button:hover {
  background-color: #80808038;
  border: none;
  color: black;

}

.scrolled-button {
  display: none;
  transition: 0.5s;

}

.jemek__navbar-item__cookies-policy {
  display: flex;
  justify-content: center;

  transition: 0.5s;
  padding-bottom: 0.5%;
  padding-top: 0.5%;
}

.jemek__navbar-item__cookies-policy a {
  position: relative;
  letter-spacing: 0.05rem;
  color: #7399c6;
}

.jemek__navbar-item__cookies-policy a:hover {
  color: #7399c6;
  text-decoration: underline;
}

.jemek__navbar-item__cookies-policy a:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background-color: #7399c6;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.jemek__navbar-item__cookies-policy a:hover:after {
  transform: scaleX(1);
  width: 100%;
}

.jemek__navbar-menu svg {
  font-size: 0.8rem;
  height: 2rem !important;
}

.jemek__navbar-item {
  transition: 0.5s;
  animation: fadeIn 0.5s ease-in-out both;
}

.jemek__navbar-item[class*="lv"]:nth-child(1) {
  transition-delay: 200ms;
}


.jemek__navbar-item[class*="lv"]:nth-child(2){
  transition-delay: 400ms;
}

.jemek__navbar-item[class*="lv"]:nth-child(3){
  transition-delay: 600ms;
}

.jemek__navbar-item[class*="lv"]:nth-child(4) {
  transition-delay: 800ms;
}

.jemek__navbar-item[class*="lv"]:nth-child(5) {
  transition-delay: 800ms;
}





@media screen and (max-width: 480px) {
  .jemek__navbar {
    position: sticky;
    padding: 0.2rem;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1);
  }

  .jemek__navbar-links {
    padding: 0.1rem;
    z-index: 102;
    background-color: white;
  }

  .jemek__navbar-breadcrumbs .crumbs {
    font-size: 0.5rem !important;
  }

  .jemek__navbar-breadcrumbs-hidden .crumbs span {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
}

.jemek__navbar-breadcrumbs .crumbs span {
  margin-left: 0rem;
  margin-right: 0rem;
}

  .jemek__navbar-breadcrumbs-hidden .crumbs {
    font-size: var(--step--2) !important;
  }

  .jemek__navbar-breadcrumbs .crumbs {
    font-size: var(--step--2) !important;
  }


  .jemek__navbar-sign button {
    padding: 0.45125rem 0.55125rem !important;
    font-size: 0.6rem !important;
    border-radius: 0.05rem;
  }

  .jemek__navbar-links_logo img {
    height: 1.5875rem !important;
  }

  .jemek__navbar-extracontent {
    z-index: 105;
    padding-left: 0.925rem;
  }

  .jemek__navbar-submenu_teaser {
    left: calc(72.4% + 1.9rem);
    width: 120%;
    top: 0;
    gap: 0.125rem;
    overflow-y: scroll;
    overflow-x: hidden;
    font-size: 0.4rem;
    padding: 0rem 1.1rem 0rem 1.25rem;
    height: 12.3rem;
    background-color: rgb(244, 244, 244);
  }

  .jemek__navbar-submenu_teaser h1 {
    position: fixed;
    left: 10%;
    top: 18%;
    display: flex;
    font-weight: 500;
    font-size: 0.9rem;
  }

  .jemek__navbar-submenu_teaser p {
    position: fixed;
    left: 10%;
    top: 30%;
    width: 53%;
    display: flex;
    font-weight: 300;
  }

  .jemek__navbar-submenu_teaser h1,
  .jemek__navbar-submenu_teaser p {
    letter-spacing: 0.2px;
    line-height: 0.675rem;
    font-family: var(--font-family);
    animation: text-focus-in 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }

  .jemek__navbar-iconright {
    font-size: 0.3rem;
    color: inherit;
    position: absolute;
    right: 0.5875rem;
    top: 47%;
    transform: translateY(-50%);
  }
  .jemek__navbar-submenu_teaser-main {
    left: calc(7.5% + 5rem);
    width: 71.6vw;
    position: fixed;
    height: 49vh;
  }

  .jemek__navbar-menu_overlay {
    top: 12.6%;
  }

  .jemek__navbar-item__cookies-policy a {
    font-size: 0.55rem;
    letter-spacing: 0.03rem;
  }
}

@media screen and (max-width: 768px) {
  .jemek__mobile-navbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    height: 50rem;
    top: calc(88.3% - -0.275rem);
    left: 0;
    width: 100%;
    background-color: #ffffff;
    cursor: auto;
    overflow-y: hidden;
    overflow-x: hidden;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    opacity: 0;
    padding: 2rem;
    padding-top: 6%;
    z-index: 102;
    animation: scale-in-ver-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  .jemek__mobnav-extracontent {
    z-index: 106;
    visibility: visible;
  }

  .jemek__navbar-container {
    display: none;
  }
}
@media screen and (min-width: 769px) {
  .jemek__mobile-navbar {
    display: none;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .jemek__navbar {

    flex-wrap: wrap;

  }

  .jemek__mobile-nav__button.inactive {
    position: fixed;
    margin: 3rem auto;
  }

  .jemek__mobile-nav__button.active {
    position: fixed;
    margin: 3rem auto;
  }

  .jemek__navbar-breadcrumbs-hidden .breadcrumbs a,
  .jemek__navbar-breadcrumbs-hidden .breadcrumbs bold {
    font-size: var(--step-0);
  }
  .jemek__navbar-breadcrumbs .breadcrumbs a,
  .jemek__navbar-breadcrumbs .breadcrumbs bold {
    font-size: var(--step-0)!important;
  }

  .jemek__navbar-item__cookies-policy a {
    position: relative;
    letter-spacing: 0.05rem;
    color: #7399c6;
    font-size: 0.7rem;
  }

  .jemek__navbar-menu_overlay {
    top: 115%;
  }

  .jemek__navbar-links {
    width: 100%;
    justify-content: space-around;
    padding: 0rem;
  }

  .jemek__navbar-breadcrumbs {
    margin: 1rem 0;
  }

  .jemek__navbar-breadcrumbs .crumbs {
    gap: 0.125rem; 
    padding-left: 1rem;
  }

  .jemek__navbar-menu_overlay-content {
    position: static;
  }

  .jemek__navbar-links_logo img {
    height: 2.3rem;
  }

  .jemek__navbar-iconright {
    font-size: 0.875rem;
    right: 1.0875rem;
  }
  x .jemek__navbar-submenu_teaser {
    left: calc(50.8% + 8.8rem);
    width: 14.6rem;
    height: 22.4rem;
  }

  .jemek__navbar-submenu_teaser-main {
    left: calc(19.2% + 6.4rem);
    width: 33rem;
    height: 26rem;
  }

  .jemek__navbar-submenu_teaser h1 {
    left: 5%;
  }

  .jemek__navbar-submenu_teaser p {
    line-height: 1.375rem;
    left: 5%;
  }

  .jemek__navbar-sign button {
    padding: 0.6rem 1rem;
    font-size: 0.6rem;
  }

  .jemek__navbar-breadcrumbs .breadcrumbs span,
  .jemek__navbar-breadcrumbs .breadcrumbs a,
  .jemek__navbar-breadcrumbs .breadcrumbs bold {
    color: #000;
    text-decoration: none;
    padding-right: 0.2rem;
    font-size: var(--step--1)!important;
  }

  .jemek__navbar-breadcrumbs-hidden .breadcrumbs span,
  .jemek__navbar-breadcrumbs-hidden .breadcrumbs a,
  .jemek__navbar-breadcrumbs-hidden .breadcrumbs bold {
    color: #000;
    text-decoration: none;
    padding-right: 0.2rem;
    font-size: var(--step--1)!important;
  }

  .jemek__navbar-extracontent {
    padding-left: 1.5rem;
  }

  .jemek__navbar-breadcrumbs-hidden .breadcrumbs {
    font-size: 0.8rem;
  }


}

@media screen and (min-width: 769px) and (max-width: 1024px) {


  .jemek__navbar-submenu_teaser-main {
    left: 34%;
    width: 100%;
    height: 100%;
  }

  .jemek__navbar-submenu_teaser h1,
  .jemek__navbar-submenu_teaser p {
    letter-spacing: 0.2px;
    line-height: 1.875rem;
    font-family: var(--font-family);
    animation: text-focus-in 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }

  .jemek__navbar-submenu_teaser h1 {
    position: fixed;
    left: 10%;
    top: 20%;
    display: flex;
    font-weight: 500;
  }

  .jemek__navbar-submenu_teaser p {
    position: fixed;
    left: 10%;
    font-size: 1rem;
    top: 40%;
    width: 85%;
    display: flex;
    font-weight: 300;
  }

  .jemek__navbar-breadcrumbs .breadcrumbs span,
  .jemek__navbar-breadcrumbs .breadcrumbs a,
  .jemek__navbar-breadcrumbs .breadcrumbs bold {
  
    font-size: 0.9rem!important;
  }
}



@media screen and (min-width: 1024px) {
  .jemek__navbar-header.lv-0 {
    padding: 60px 72px 44px 80px;
  }

  .jemek__navbar-header.lv-1 {
    padding: 60px 60px 44px 40px;
  }

  .jemek__navbar-header.lv-2 {
    padding: 60px 0 44px 65px;
    width: 127%;
  }

  .jemek__navbar-breadcrumbs .breadcrumbs span,
.jemek__navbar-breadcrumbs .breadcrumbs a,
.jemek__navbar-breadcrumbs .breadcrumbs bold {
  color: #000;
  text-decoration: none;
  padding-right: 0.2rem;
  font-size: 1rem!important;
}
}

@media screen and(min-width: 769px) {
  .jemek__navbar-header.lv-0 {
    padding: 30px 72px 44px 40px;
  }

  .jemek__navbar-header.lv-1 {
    padding: 40px 60px 44px 40px;
  }

  .jemek__navbar-header.lv-2 {
    padding: 40px 0 44px 65px;
    width: 127%;
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .jemek__hero-content button {
    font-size: 0.6rem!important;
}
}


@media screen and (min-width: 1280px) {
  .jemek__navbar-header.lv-1 > .jemek__navbar-header__inner button,
  .jemek__navbar-header.lv-1 > .jemek__navbar-header__inner a,
  .jemek__navbar-header.lv-2 > .jemek__navbar-header__inner button,
  .jemek__navbar-header.lv-2 > .jemek__navbar-header__inner a {
    font-size: var(--step-0)!important;
  }

  .jemek__navbar-submenu_teaser p {
    font-size: var(--step-0)!important;
  }
}