.jemek__feepaying-header-wrapper {
        background-color: #5997e9;
    }
    
    .jemek__feepaying-header-contentbox-container {
        padding-top: 2.1875rem!important;
    }
    
    .feepaying-subheader {
        padding-right: 7.5rem;
    }

    .jemek__feepaying-header-item_hover {
        transition: transform 0.5s;
      }
    
    .jemek__feepaying-header-item_hover:hover {
        transform: scale(1.02);
        cursor: pointer;
        
  
    }
    
    .jemek__feepaying-column {
        display:flex!important;
        flex-direction: column;
        justify-content: center;
    }

    .jemek__fee-paying__button {
        padding: 0.5rem;
        font-size: 1rem;
        background-color: black;
        color: white;
        border-radius: 0.5rem solid white;
        border: 1rem;
        transition: 0.5s;
    }

    .jemek__fee-paying__image {
        width: 100%;
    }

    .jemek__fee-paying__button:hover {
        color: black;
        background-color:white;

    }
    
    .jemek__feepaying-header-texts h1 {
        display: flex;
        align-items: flex-start;
        font-size: var(--step-6)!important;
        color: white;
        margin-left: 0;
        margin-right: 0;
        width: 90%;
        letter-spacing: 0.0625rem;
        line-height: 4.5rem;
    }
    
    .jemek__feepaying-header-texts p {
        color: white;
        font-size: 1.125rem;
        padding-top: 1.875rem;
        padding-bottom: 1.875rem;
        letter-spacing: 0.05rem;
    }
    
    .jemek__feepaying-header-row {
        padding-top: 1.5625rem;
    }
    
    .jemek__feepaying-bodyone {
        padding-top: 12.5rem;
        padding-bottom: 12.5rem;
    }
    
    .jemek__feepaying-bodyone-text h2 {
        padding-bottom: 1.25rem;
    }
    
    .jemek__feepaying-bodyone-text p {
        font-size: 1.375rem;
        margin-left: auto!important;
        margin-right: auto!important;
        width: 100%!important;
        padding-top: 1.25rem;
        line-height: 4rem;
    }

    

    
    .typewriter {
        overflow: hidden; /* Hide overflowing text */
        animation: typewriter 4s steps(20) 1s infinite;
    }
    
    @keyframes typewriter {
        from {
            width: 0;
        }
        to {
            width: 100%;
        }
    }

        






.text-pop-up-top {
	-webkit-animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}



  /**
 * ----------------------------------------
 * animation text-pop-up-top
 * ----------------------------------------
 */


 @-webkit-keyframes text-pop-up-top {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      text-shadow: none;
    }
    100% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 20px 10px rgba(0, 0, 0, 0.3);
    }
  }
  @keyframes text-pop-up-top {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      text-shadow: none;
    }
    100% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 20px 10px rgba(0, 0, 0, 0.3);
    }
  }
  
  @-webkit-keyframes text-drop-down {
    0% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 20px 10px rgba(0, 0, 0, 0.3);
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      text-shadow: none;
    }
  }
  
  @keyframes text-drop-down {
    0% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 20px 10px rgba(0, 0, 0, 0.3);
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      text-shadow: none;
    }
  }

  @media screen and (max-width: 480px) {

    .jemek__feepaying-header-texts p {
        color: white;
        font-size: 0.6rem;
        padding-top: 0rem;
        padding-bottom: 0rem;
        letter-spacing: 0.05rem;
    }

    .jemek__feepaying-header-texts h2 {

        font-size: 1rem;
        line-height: 1.2rem;
        width: 100%;
    }

    .jemek__fee-paying__button {
        padding: 0.3rem;
        font-size: 0.5rem;
        background-color: black;
        color: white;
        border-radius: 0.5rem solid white;
        border: 1rem;
        transition: 0.5s;
    }

    .jemek__feepaying-header-row {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .jemek__fee-paying__image {
        width: 100%;
        height: auto;
    }

    .jemek__feepaying-bodyone-text h2 {
        font-size: 1rem;
        padding-bottom: 0.5rem;
    }

    .jemek__feepaying-bodyone {
        padding-top: 4rem;
        padding-bottom: 2rem;
    }

    .jemek__feepaying-bodyone-text p {
        padding-top: 0.1rem;
        font-size: 0.8rem;
        line-height: 1.5rem;
    }

    .jemek__feepaying-header-texts h1 {
        line-height: 2rem;
    }
      }
      
      @media screen and (min-width: 481px) and (max-width: 768px) {

        .jemek__feepaying-header-wrapper {
            padding: 2rem 0.8rem;
        }

        .jemek__feepaying-header-row {
            padding-top: 1rem;
        }

        .jemek__feepaying-header-texts h2 {
            font-size: 2rem;
            letter-spacing: 0.0625rem;
            line-height: 2.5rem;
            width: 100%;
        }

        .jemek__feepaying-header-texts h1 {

            line-height: 2rem;
        }

        .jemek__feepaying-header-texts p {
            color: white;
            font-size: 0.8rem;
            padding-top: 0.6rem;
            padding-bottom: 0.6rem;
            letter-spacing: 0.05rem;
        }

        .jemek__feepaying-bodyone-text h2 {
            padding-bottom: 0.05rem;
            font-size: 1.5rem;
        }

        .jemek__feepaying-bodyone-text p {
            font-size: 1rem;
            line-height: 2rem;
            padding-top: 0.8rem;
        }

        

        .jemek__fee-paying__button {
            padding: 0.4rem;
            font-size: 0.6rem;
            background-color: black;
            color: white;
            border: 1rem;
            transition: 0.5s;
        }

        .jemek__feepaying-bodyone {
            padding-top: 6rem;
            padding-bottom: 3rem;
        }

        .jemek__feepaying-bodyone {
            padding-top: 8rem;
            padding-bottom: 7rem;
        }

    
      }
      

      @media screen and (min-width: 769px) and (max-width: 1024px) {
        .jemek__feepaying-header-texts p {

            padding-top: 0.475rem;
            padding-bottom: 0.875rem;
        }

        .jemek__feepaying-bodyone-text p {
            line-height: 2.575rem;
        }

        .jemek__feepaying-bodyone {
            padding-top: 8rem;
            padding-bottom: 5rem;
        }

        .jemek__feepaying-header-texts h1 {

            line-height: 3.3rem;
        }
      }

      @media screen and (min-width:1320px) {
        .jemek__feepaying-header-texts p {
            color: white;
            font-size: var(--step-0)!important;
            padding-top: 1rem;
            padding-bottom: 1rem;
            letter-spacing: 0.05rem;
        }

        .jemek__fee-paying__image {
            width: 100%;
            padding: 3.5rem;
        }
      }