.jemek__header {
  background-color: #04040408;
  padding-top: 0rem;
  padding-bottom: 0rem;
  padding-left: 0rem;
  padding-right: 0rem;
  background-image: url(/home/ghost/Documents/codingprojs/react_app_jemek/frontend_react/src/assets/hero-background-four.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: local;
  transform-origin: top left;
  width: 100%;
  height: 100%;
}

.gradient__text.header {
  color: white;
  font-weight: 400;
  letter-spacing: 0px;
  font-size: 1.2rem;
}



.jemek__hero-content.header p {
  color: white;
  margin-top: 1.5%!important;
  margin-bottom: 1.5%!important;

  letter-spacing: 0.5px;
  font-size: 0.5em;
}

.jemek__header-column.hero {
  display: none;
}

.jemek__header-container {
position: relative;
padding: 1.5rem 0rem 1.5rem 0rem;
}

.jemek__header-column .col-lg-6 {
  width: 70% !important;
}






.jemek__header-column {
  justify-content: center;
  padding: 0.4rem!important;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%!important;
}

.jemek__header-column-two {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem !important;
  padding-left: 2rem!important;
  display: flex;
  align-items: center;
  height: 100%;
}

.jemek__header-content {
  flex: 1;
  text-align: center;
  padding-right: 0.625rem;
}

.jemek__header-image {
  flex: 1;
}
.jemek__header-row {
  width: 100% !important;
  margin: 0 !important;
  justify-content: flex-start;
}

.jemek__header-herobtn-one {
  color: #000000;
}

.btn-custom-transparent {
  background-color: transparent;
  color: black;
  border: 0.0625rem solid black;
}

.btn-custom-black {
  background-color: black;
  color: rgb(0, 0, 0);
}

.btn-custom-transparent:hover,
.btn-custom-transparent:focus {
  background-color: black;
  color: white;
}

.btn-custom-black:hover,
.btn-custom-black:focus {
  background-color: white;
  color: black;
}

.jemek__hero-content.btn {
  margin-right: 0.625rem;
  border: 0.0625rem solid;
  border-radius: 0.0625rem;
}

.jemek__hero-content {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  max-width: 1440px;
  margin: 0 auto;
}

.jemek__hero-content .jemek__hero-content__wrapper {
  width: 80%;
}

.jemek__header-item {
  display: flex;
  height: 100%;
  align-items: center;
}

.jemek__header-item img {
  min-height: 38em !important;
  width: 100%;
  visibility: hidden;
}

.jemek__header-item-link {
  cursor: default;
  /* width: 100%; */
  display: flex;
  /* height: 100%; */
}

.jemek__hero-content button {
  font-size: 0.4375em;
  padding: 0.35em !important;
  border-radius: 0.5px;
  margin-right: 0rem!important;
}

.jemek__header-herobtn-one {

  color: white !important;
  padding: 0.4rem;
  background-color: #192e47;
  transition: 0.5s;
  border: 1px solid #192e47;
  letter-spacing: 1px;
}

.jemek__header-herobtn-two {
  color: white!important;
  margin-left: 0.3rem;
  padding: 0.4rem;
  background-color: transparent;
  transition: 0.5s;
  border: 1px solid white;
  letter-spacing: 1px;
}

.jemek__header-herobtn-one:hover {
  color: white!important;
  background-color: #2e80cf!important;
  border: 1px solid #2e80cf;


}


.jemek__header-herobtn-two:hover {
  color: black!important;
  background-color: white;



}

.jemek__practiceareas {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  padding-bottom: 0;
    max-width: 1920px;
  margin: 0 auto;
}

.jemek__practiceareas-heading {
  color: white;
  display: flex;
}

.jemek__practiceareas h1 {
  font-size: 3.0625rem;
  line-height: 2.8125rem;
  font-weight: 300!important;
  color: white;
  font-family: var(--font-family);
}

.jemek__practiceareas h2 {
  font-size: 1.875rem;
  line-height: 2.625rem;
  font-weight: 300;
  color: rgb(0, 0, 0);
  font-family: var(--font-family);
  background-color: #dcdddd;
  padding: 1.5rem;
  margin-top: -5rem;
  margin-bottom: 3.75rem;
}

.jemek__practiceareas-text p {
  color: white !important;

  line-height: 2.1875rem !important;
  font-weight: 300 !important;
  max-width: 37.5rem !important;
  margin: 0 auto !important;
  padding-top: 1% !important;
  font-family: var(--font-family);
  margin-top: 2rem;
}

.jemek__practiceareascard-teaser-row {
  grid-template-columns: repeat(2, 1fr);
  display: grid !important;
  gap: 2rem;
  column-gap: 2rem;
  margin-left: auto!important;
  margin-right: auto!important;
  row-gap: 2rem;
  padding-top: 1.5625rem;
}

.jemek__practiceareascard-teaser-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 0.9375rem;
}

a.jemek__practiceareascard-link {
  width: 100%;
}

.jemek__practiceareascard-img-wrapper {
  width: inherit;
}

.jemek__practiceareascard-img-wrapper img {
  width: 100%;
  height: auto;
}

.jemek__practiceareascard-data-container {
  background-color: #192e47;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-attachment: fixed;
  display: flex !important;
  justify-content: center;
  flex-direction: column;

}

.jemek__practiceareascard-components {
  display: flex;
  justify-content: center;
}

.jemek__practiceareascard-components button {
  display: inline-block;
  background: transparent;
  line-height: 1.5rem;
  flex: 50 1;
  font-size: var(--step-1)!important;
  transition: 0.5s;
  font-weight: 400;

  color: white;
  border: none;
}

.jemek__practiceareascard-teaser-columnone,
.jemek__practiceareascard-teaser-columntwo,
.jemek__practiceareascard-teaser-columnthree {
  flex: 0 0 auto;
  width: 100% !important;
  padding-left: 0rem!important;
  padding-right: 0rem!important;
}

.jemek__practiceareascard-teaser-columnone {
  padding-left: 0rem!important;
  padding-right: 0rem!important;
}

.jemek__practiceareascard-components-cards {
  display: flex !important;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
  max-width: 1320px;
  width: 100% !important;
  padding: 1.5rem 1rem;
  flex: 40%;
}

.jemek__practiceareascard-component button.active {
  background-color: #192d44;
  color: white;
  border-radius: 0.0625rem !important;
  border-color: white !important;
}

.jemek__practiceareascard-component button.inactive {
  background-color: #2a507c;
  color: white;
  opacity: 70%;
}

.jemek__practiceareascard-component button.active:hover {
  background-color: #1e3652;
  color: white;
}

.jemek__practiceareascard-component button.inactive:hover {
  background-color: #192d44;
  color: white;
}

.jemek__practiceareascard-component-one,
.jemek__practiceareascard-component-two,
.jemek__practiceareascard-component-three {
  background: none;
  display: flex;
  flex: 50 1;
}

.jemek__practiceareascard-component-one button.active {
  background-color: #192d44;
  color: white;
  border-radius: 0.0625rem !important;
  border-color: white !important;
}

.jemek__practiceareascard-component-one button.inactive {
  background-color: #2a507c;
  color: white;
  opacity: 85%;
}

.jemek__practiceareascard-component-one button.active:hover {
  background-color: #1e3652;
  color: white;
}

.jemek__practiceareascard-component-one button.inactive:hover {
  background-color: #192d44;
  color: white;
}

.jemek__practiceareascard-component-two button.active {
  background-color: #163355;
  color: white;
}

.jemek__practiceareascard-component-two button.inactive {
  background-color: #35659c;
  color: white;
  opacity: 85%;
}

.jemek__practiceareascard-component-two button.active:hover {
  background-color: #1e3652;
  color: white;
}

.jemek__practiceareascard-component-two button.inactive:hover {
  background-color: #0f2746;
  color: white;
}

.jemek__practiceareascard-component-three button.active {
  background-color: #192d44;
  color: white;
  border-radius: 0.0625rem !important;
  border-color: white !important;
}

.jemek__practiceareascard-component-three button.inactive {
  background-color: #2a507c;
  color: white;
  opacity: 70%;
}

.jemek__practiceareascard-component-three button.active:hover {
  background-color: #1e3652;
  color: white;
}

.jemek__practiceareascard-component-three button.inactive:hover {
  background-color: #1c395a;
  color: white;
}

.jemek__practiceareascard-button button {
  background: #192d44;
  border: 0.125rem solid white;
  border-radius: 0.0625rem;
  color: white;
  padding-left: 6%;
  padding-right: 6%;
}

.jemek__practiceareascard-button button:hover {
  background: white;
  border: 0.125rem solid white;
  border-radius: 0.0625rem;
  color: #192d44;
}

.jemek__practiceareascard-component-one-label.active,
.jemek__practiceareascard-component-two-label.active,
.jemek__practiceareascard-component-three-label.active {
  border: 0.125rem solid white;
}

.jemek__practiceareascard-teaser-container.container {
  max-width: 100%!important;
}



.jemek__practiceareascard-teaser-itemone {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  flex-wrap: nowrap;
  margin: auto;
  align-items: center;
}



.jemek__practiceareascard-textsection-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.625rem 0 0 0;
}

.jemek__practiceareascard-textsection-wrapper p,
.jemek__practiceareascard-textsection-wrapper h4
 {
  color: white !important;
  min-height: 1rem;
}

.jemek__practiceareas-text {
  background-color: #192e47;
  width: 100%;
  color: white;
  padding-top: 7%;
  padding-bottom: 7%;
  margin-top: 0rem;
}

.jemek__practiceareas p {
  color: black;
  font-size: 1rem;
  line-height: 1.875rem;
  font-family: var(--font-family);
  margin-top: 0.5rem;
  font-weight: 400;
}


.jemek__practiceareas-container {
  flex: 1.5;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;


}

.jemek__practiceareas-content {
  display: flex;
  justify-content: center;
}

.jemek__aboutus {
  background-color: #ffffff59;

  max-width: 1920px;
  margin: 0 auto;
}

.jemek__aboutus-header-text {

  font-weight: 400;
}

.jemek__aboutus-header-text h1 {
  font-size: 1rem;
}

.jemek__about-us__text {
  font-size: 1.2rem;
  color: #3b3b3b;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.jemek__aboutus-container {
  max-width: 1440px;
  margin: 0 auto;
}

.jemek__aboutus-row {
  /* padding: 50px; */
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.jemek__aboutus-column {
  width: 50%!important;
  padding: 5%!important;

}

.jemek__aboutus-column-two {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 0rem!important;
  padding-left: 2rem !important;
  width: 50% !important;
}

.jemek__aboutus__copy-right {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start;
  justify-content: space-between;
}

.react-reveal.jemek__aboutus-container.container {
  max-width: 1620px;
  margin: 0 auto;
}

.jemek__aboutus-copy-right-muted h1 {
  font-size: 0.625rem;
  margin-top: auto;
  color: #80808091;
  padding-bottom: 2rem;
  letter-spacing: 0.05rem;
}

.jemek__aboutus-item {
  display: flex;
}

.jemek__aboutus-item img {
  width: 100% !important;
  height: 40rem;
  object-fit: cover;
}

.jemek__about-us__link {
  width: 100%;
  transition: transform 0.5s;
}

.jemek__about-us__link:hover {
  transform: scale(1.08);
}



.jemek__aboutus-item button {
  background-color: rgb(0, 0, 0) !important;
  color: white !important;
  margin-top: auto;
  padding: 0.7rem;
  border: 0.005rem solid white;
  transition: 0.5s;
  font-size: 0.9rem;
  border: 1px solid;
}

.jemek__aboutus-item button:hover {
  background-color: #5997e9!important;
  color: white!important;
  border: 1px solid;
}



.jemek__fees {
  background-color: #6396d8;
  max-width: 1920px;
  margin: 0 auto;
}


.jemek__feescard-data-container {
  background-color: #192e47;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-attachment: fixed;
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  padding-top: 3.125rem;
}

.jemek__feescard-items {

  position: relative !important;
  max-width: 100% !important;
  display: grid !important;

  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  grid-template-rows: minmax(20rem, auto);
 /* margin: 1.25rem; */
  grid-auto-flow: dense;
  width: 100%;
  height: 100%;
  grid-gap: 2.5rem;
  padding-bottom: 3%;
}

.jemek__feescard-items .jemek__feescard-item-wrapper {
  display: flex;
  background: #e3e5e959;
  width: 100%;
  padding: 10%;
  /* place-items: center; */
  /* justify-items: center; */
  font-size: 0.9375rem;
  color: black;
  transition: transform 0.5s ease-in-out;
}

.jemek__feescard-items .jemek__feescard-item-wrapper:hover {
  background: #5997e97d;
  transform: scale(1.05);
  color: white;
  box-shadow: 1px 23.1px 23px rgba(2, 0, 0, 0.2);
}

.jemek__feescard-one, .jemek__feescard-two {
  display: flex;
  flex-direction: column;
  transform: translate(0px, 0px);
  width: 100%;
  opacity: 1;
}

.jemek__fees-column-one {
  padding-top: 1%;
  padding-bottom: 1%;
}

.jemek__fees-column-one h1 {
  font-size: 2rem;
  padding-bottom: 1rem;
}

.jemek__fees-column-one span {
  color: #ffffff;
}

.jemek__feescard-img-wrapper {
  width: 100%;
}

.jemek__feescard-img-wrapper img {
  width: 100%;
  min-height: 20rem;
  object-fit: cover;
}


.card-content__text-bottom {
  margin-top: auto;
}

.jemek__feescard-button button {
  background: #02060a;
  border: 1px solid white;
  border-radius: 0.0625rem;
  color: white;
  padding: 3%!important;
  transition: 0.5s ease-in-out;
}

.jemek__feescard-button {
  transition: 0.5s;
}

.jemek__feescard-button button:hover {
  background: white;
  border: 0.125rem solid white;
  border-radius: 0.0625rem;
  color: #192d44;
  height: 100%;
  transition: 0.5s;
}

.jemek__feescard-item-wrapper:hover .jemek__feescard-textsection-wrapper h4,
.jemek__feescard-item-wrapper:hover .jemek__feescard-textsection-wrapper p {
  color: white!important;
  transition: 0.5s ease-in-out;
}

.jemek__feescard-teaser-text {
  padding-bottom: 0.5rem;
  transition: 0.5s ease-in-out;
}

.jemek__feescard-textsection-wrapper {
  display: flex;
  flex-direction: column;
  transition: 0.5s ease-in-out;
  padding: 5% 0 0 0;
  height: 100%;
}

.jemek__feescard-textsection-wrapper p {
  color: #000000 !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  /* Set the initial opacity to 0 */
  transition: background-color 0.3s ease;
  pointer-events: none;
  /* Prevents the overlay from blocking interactions with the content */
}

.jemek__contactus-column-one-item h1 {
  font-size: 4rem;
  font-weight: 400;
}

.jemek__contactus-formbtn-one {
  margin-top: 0.625rem;
  background-color: #000000 !important;
  color: white !important;
}

.jemek__contactus {
  background-color: #101e2f;
  color: white;
  font-weight: 300;
  margin: 0 auto;
  max-width: 1920px;
  padding-bottom: 5%;
  padding-top: 5%;


}

.jemek__contactus-column {
  margin-top: 0.875rem !important;
}

.jemek__contactus-column-one {
  display: flex !important;
  justify-content: center !important;
  align-items: center;
}

.jemek__contactus-column-one-item {
  margin: 0.975rem;
  line-height: 1.875rem;
}

.jemek__contactus-column-one-item p {
  margin-top: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.1px;
}

.jemek__contactus-text-highlight {
  color: #5997e9;
}

.jemek__contactus-map {
  max-width: 100% !important;
  height: auto;
  margin-top: -15rem;
  margin-bottom: -12rem;
  margin-left: -5.625rem !important;
  height: 41.625rem !important;
  box-shadow: -1.4125rem 0.3125rem 0.0125rem 0px rgba(0, 0, 0, 0.4);
}



/* Disable autofill styling for input fields */
input:-webkit-autofill {
  -webkit-box-shadow: none !important;
}

/* Disable autofill styling for select fields */
select:-webkit-autofill {
  -webkit-box-shadow: none !important;
}

/* Disable autofill styling for textarea fields */
textarea:-webkit-autofill {
  -webkit-box-shadow: none !important;
}



@media screen and (max-width: 320px) {
.jemek__practiceareascard-components button {
    font-size: 0.425em!important;
    line-height: 1.175em!important;
    padding: 0.675rem!important;
}
}



@media screen and (max-width: 480px) {

  .jemek__header {
    transform: scale(1);
  }

  .jemek__hero-content.header p {
    line-height: 1.4rem;
    margin-top: 0.5rem!important;
    margin-bottom: 0.5rem!important;
  }

  .gradient__text.header {
    line-height: 1.8rem;
  }



  .jemek__header-column-two {
    justify-content: center;
    padding: 0.025em !important;
    display: flex;
    align-items: center;
    height: 100%;
  }



  .jemek__header-item img {
    min-height: 13.6em !important;
    width: 100%;
  }
  


  .jemek__practiceareas h1 {
    font-size: 1.5em;
    line-height: 1.875em;
  }

  .jemek__practiceareas h2 {
    font-size: 1.125em;
    line-height: 1.5em;
  }

  .jemek__practiceareas p {
    color: var(--color-subtext);
    line-height: 1.6em;
    font-family: var(--font-family);
    margin-top: 0.5rem!important;
    margin-bottom: 0.6rem!important;
  }

  .jemek__practiceareas-text {
    padding-bottom: 2.5rem;
    padding-top: 2.5rem;
  }

  .jemek__practiceareas-text p {

    line-height: 1.875em !important;
    width: 83% !important;
  }

  .jemek__practiceareascard-data-container {
    padding-top: 0rem;
  }

  .jemek__practiceareascard-teaser-row {
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    padding: 0.35em;
  }

  .jemek__practiceareascard-components button {
    font-size: 0.625em;
    line-height: 1rem;
    padding: 9%;
    font-size: var(--step-0)!important;
  }

  .jemek__practiceareascard-teaser {
    margin-right: 0;
  }

  .jemek__practiceareascard-teaser-text {
    padding-bottom: 0.1375rem;
}

  .jemek__practiceareascard-button button {
    background: #192d44;
    border: 2px solid white;
    border-radius: 1px;
    color: white;
    padding: 0.75em;
    font-size: 0.525em;
  }

  .jemek__aboutus {
    background-color: #ffffff59;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

  .jemek__aboutus-container {
    padding-left: 0;
  }

  .jemek__aboutus-column {
    padding-left: 0rem!important;
    padding-right: 0rem!important;
    padding-bottom: 0rem!important;
  }

  .jemek__aboutus-column-two {
    padding-left: 1em !important;

    width: 50% !important;
  }

  .jemek__aboutus-row {
    display: flex !important;
    width: 100% !important;
    margin: 0 !important;
    object-fit: cover;
    /* height: 15em !important; */
    flex-direction: column;
  }

  .jemek__aboutus-column, .jemek__aboutus-column-two {
    width: 100%!important;
  }

  .jemek__aboutus-copy-right-muted p {
    font-size: 0.55rem!important;
  }

  .jemek__aboutus-item img {
    width: 100% !important;
    height: 23rem;
    padding: 4.5%;
  }

  .jemek__aboutus-header-text {
    padding-bottom: 0.3rem;
    font-weight: 400;
  }

  .jemek__aboutus-item button {
    font-size: 0.6em;
    padding: 0.65em !important;
  }

  .jemek__aboutus__copy-right-content h1 {
    font-size: 0.5rem;
    width: 100%;
  }

  .jemek__about-us__text {
    font-size: 0.4rem;
    color: #3b3b3b;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

  .jemek__aboutus-copy-right-muted h1 {
    font-size: 0.2rem;
    /* margin-top: -1.7rem; */
    color: #80808091;
    letter-spacing: 0.05rem;
    padding-bottom: 0.1rem;
  }

  .jemek__fees-column-one {
    padding-top: 1rem;

  }

  .jemek__fees-column-one h1 {
    font-size: 1rem;
    padding-bottom: 0.2rem;
  }

  .jemek__feescard-items {
    display: flex!important;
    margin: 0rem;
    grid-gap: 1rem;
    padding-left: 0rem!important;
    flex-direction: row;
    height: 100%;
    place-items: unset;
  }

  .jemek__feescard-components button {
    font-size: 1.125em;
    line-height: 1.875em;
  }

  .jemek__feescard-teaser {
    margin-right: 0;
  }
  .jemek__feescard-img-wrapper img {
    width: 100%;
    min-height: 8.5rem!important;
  }

  .jemek__feescard-button button {
    background: #02060a;
    border: 1px solid white;
    border-radius: 1px;
    color: white;
    padding: 4%;
    font-size: 0.6em;
  }

  .jemek__feescard-teaser-text {
    padding-bottom: 0.075em;
  }
  .jemek__feescard-teaser-text h3 {
    font-size: 1em;
  }


  .jemek__feescard-items .jemek__feescard-item-wrapper {
    background: #e3e5e959;
    padding: 4%;
    width: 50%;
  }


  .jemek__feescard-teaser-row {
    width: 100% !important;
    display: flex!;
    flex-direction: column !important;
    padding-top: 0.5625em;
    margin-left: 0 !important;
  }

  .jemek__feescard-textsection-wrapper p {
    font-size: 0.8rem !important;
  }


}

@media screen and (min-width: 481px) and (max-width: 580px) {

  .jemek__feescard-items .jemek__feescard-item-wrapper {
  
    padding: 1.5rem!important;

}

  .jemek__feescard-img-wrapper img {
    width: 100%;
    min-height: 11rem!important;
}
  
}

@media screen and (min-width: 481px) and (max-width: 768px) {

  .jemek__header {
    transform: scale(1);
  }
    .jemek__header {
      padding: 1rem 1rem;

    }

    .jemek__header-container {
        max-width: 100%!important;
    }
  
    .jemek__header-row {
    width: 100% !important;
    margin: 0 !important;
  }

  .jemek__header-column {
    padding: 0.325rem !important;
}

.jemek__hero-content h1 {
  font-size: 1.5rem;
}


.jemek__hero-content p {
  font-size: 0.8rem;
}

.jemek__header-column-two {
  padding-left: 2rem!important;
}

  .jemek__header-item img {
    min-height: 23em !important;
    width: 100%;
  }


  .jemek__header-row .btn {
    margin-right: 0.625rem;
    border: 0.0625rem solid;
    font-size: 0.6rem;
    border-radius: 0.0625rem;
}

  .jemek__practiceareascard-components button {
    padding: 1.3rem;
    font-size: 1rem;
    line-height: 1.3rem;
  }

  .jemek__practiceareas-container {
    width: 50%;
  }

  .jemek__practiceareascard-teaser-row {
    margin-left: 0rem!important;
    margin-right: 0rem!important;
    display:flex!important;
    flex-direction: column;
  }
  .jemek__practiceareas p {
    min-height: 4rem;
    font-size: 0.8rem;
}

  .jemek__practiceareas-text {
    padding-bottom: 30px;
  }

  .jemek__practiceareas-text p {
    font-size: 0.95em !important;
    line-height: 1.875em !important;
    width: 50% !important;
  }

  .jemek__practiceareascard-components-cards {
    width: 100% !important;
    padding: 1em 1em 1em 1em;
    max-width: 580px;
  }

  .jemek__aboutus {
    padding: 2rem 1rem;
  }

  .jemek__aboutus-item img {
    width: 100% !important;
    height: auto;
    object-fit: cover;
  }

  .jemek__aboutus-container {
    max-width: 100%!important;
  }

  .jemek__aboutus-copy-right-muted p {
    font-size: 0.6rem!important;
  }

  .jemek__aboutus-row {
    display: flex !important;
    flex-direction: row !important;
    width: 100% !important;
    margin: 0 !important;
    object-fit: cover;
    /* height: 15em !important; */
  }

  .jemek__aboutus-column-two {
    width: 50% !important;
    padding-left: 1rem !important;
  }

  .jemek__aboutus__copy-right-content h1 {
    font-size: 0.9rem;
  }

  .jemek__about-us__text {
    font-size: 0.6rem;
    color: grey;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

  .jemek__aboutus-copy-right-muted h1 {
    font-size: 0.5rem;
    padding-bottom: 1rem;
}

.jemek__aboutus-item button {
  background-color: rgb(0, 0, 0) !important;
  color: white !important;
  margin-top: auto;
  padding: 0.375rem;
  font-size: 0.5rem;
  border: 0.005rem solid white;
  transition: 0.5s;
}


.jemek__fees-container, 
.jemek__feescard-teaser-container {
  max-width: 100%!important;
}


  .jemek__fees-column-one {
    width: 100% !important;
  }

  .jemek__feescard-items {
    display: flex!important;
    margin: 0rem;
    grid-gap: 1rem;
    padding-left: 0rem!important;
    flex-direction: row;
    height: 100%;
    place-items: unset;
}

  .jemek__feescard-items .jemek__feescard-item-wrapper {

    margin-left: auto;
    margin-right: auto;
    width: 80%;
    padding: 1.5rem;

  }

  .jemek__feescard-img-wrapper img {
    width: 100%;
    min-height: 14rem;
    object-fit: cover;
}

.jemek__feescard-teaser-text {
  padding-bottom: 0.5rem;
}

.jemek__feescard-teaser-text h3 {
  font-size: 1.2rem;
}

.jemek__feescard-textsection-wrapper p {
  color: #000000 !important;
  font-size: var(--step-0)!important;
}

.jemek__feescard-button button {
  font-size: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .jemek__header {
    transform: scale(1);
  }
  .jemek__header-row {
    width: 100% !important;
    margin: 0 !important;
  }

  .jemek__header-column-two {
    width: 100% !important;
  }

  .jemek__header-item img {
    min-height: 30em !important;
  }

  .jemek__practiceareascard-components-cards {
    width: 100% !important;
    padding: 50px 20px 90px 20px;
  }


  .jemek__data-container {

    padding: 25px 30px;
    
}

  .jemek__aboutus {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .jemek__aboutus-row {
    width: 100%;
  }

  .jemek__aboutus-item img {
    width: 100% !important;
    height: 30rem;
    object-fit: cover;
  }

  .jemek__aboutus-column-two {
    width: 50% !important;
  }

  .jemek__aboutus-header-text {
    font-size: 1.6rem;
  }
  .jemek__aboutus-item button {
    font-size: 0.9rem;
  }

  .jemek__aboutus-copy-right-muted p {
    font-size: 0.7rem!important;
  }


  .jemek__contactus-column-one,
  .jemek__contactus-column {
    padding-left: 15px;
    padding-right: 15px;
  }

  .jemek__feescard-items {
    margin: 0rem;
  }

  .jemek__feescard-teaser-text {
    padding-bottom: 0.5rem;
}

  .jemek__feescard-img-wrapper img {
    min-height: 20rem;
  }
}

@media screen and (min-width: 768px) {
  .jemek__data-container {
    padding: 6% 7%;
}

.jemek__hero-content button {
  font-size: 0.8rem;
}

.jemek__practiceareascard-components button {
  padding: 8%!important;
}

.jemek__header-container {
  position: relative;
  padding: 3.5rem 0rem 3.5rem 0rem;
}

.jemek__practiceareascard-textsection-wrapper p {

  min-height: 3.9rem;
}






}

@media screen and (min-width: 1024px) {
  .jemek__header-row {
    width: 100% !important;
    margin: 0 !important;
    display: flex;
  }

  .jemek__header-column {
    width: 50% !important;
  }

  .jemek__header-column-two {
    width: 100% !important;
  }

  .jemek__contactus-row {
    flex-direction: row;
  }

  .jemek__contactus-column-one,
  .jemek__contactus-column {
    padding-left: 15px;
    padding-right: 15px;
  }

  .jemek__practiceareascard-components-cards {
    padding: 5rem 3rem 5rem 3rem;
    flex: 40% 1;
}

.jemek__hero-content button {
  font-size: 0.9rem;
}

@media screen and (min-width:1320px) {
  .jemek__practiceareas p {

    font-size: var(--step-0)!important;
}

.jemek__practiceareas-text p {
  font-size: var(--step-1)!important
}

.jemek__aboutus-copy-right-muted p {
  font-size: var(--step--1)!important;
}

.jemek__feescard-textsection-wrapper p {
  color: #000000 !important;
  font-size: var(--step-0)!important;
}

.jemek__contactus-column-one-item p {
  font-size: var(--step-0)!important;
}


}



@media screen and (min-width:1400px) {
  .jemek__header-container {
    position: relative;
    padding: 10rem 0rem 10rem 0rem;
  }


}

}





